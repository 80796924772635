import { useEffect, useState } from 'react';

import {CircularProgress} from "@material-ui/core";
import { useAuth } from 'react-oidc-context';

import { IsSameCrefoIdResponse } from '../../api-clients/core-service-api-react';
import {waitForVerificationStyles} from "../../CrefoTheme";
import { useAppSelector } from '../../state/hooks';
import { selectState } from '../../state/ident/identSlice';
import { isMatchingCrefoNummer } from '../../state/targetPlatform/targetPlatformApi';
import { MismatchUnverifiedCrefonummer } from '../MismatchUnverifiedCrefonummer/mismatch-unverified-crefonummer';

type ContainerProps = {
  children: React.ReactNode;
};

export const CrefoIdGuard = (props: ContainerProps) => {
  const classes = waitForVerificationStyles();
  const { children } = props;
  const state = useAppSelector(
    selectState,
    (a, b) =>
      a.targetPlatform.order?.orderId === b.targetPlatform.order?.orderId
  );
  const auth = useAuth();
  const [isMatching, setIsMatching] = useState<IsSameCrefoIdResponse>({
    isSameCrefoId: false,
    isMember: false,
  });
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    if (state.targetPlatform.order?.orderId) {
      setIsLoading(true);
      (async () => {
        try {
          const matching = await isMatchingCrefoNummer(
            auth.user?.access_token as string,
            state
          );
          setIsMatching(matching);
          setIsLoading(false);
        } catch (e) {
          setIsMatching({
            isSameCrefoId: false,
            isMember: false,
          });
          setIsLoading(false);
        }
      })();
    }
  }, [state]);

  return (
    <>
      {isLoading ? <CircularProgress size={80} className={classes.myEsgSpinner}/> : (
          (isMatching.isMember || isMatching.isSameCrefoId) ? (
              children
          ) : (
              <MismatchUnverifiedCrefonummer {...isMatching} />
          )
      )}

    </>
  );
};