import { Grid, Typography, List, ListItemText } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import crefoTheme, { imprintStyles } from '../../CrefoTheme';
import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { Header } from '../Header/Header';

export const Imprint = () => {
  const classes = imprintStyles();
  const { t } = useTranslation();
  const verifierName = useAppSelector(selectVerifierName);

  return (
    <div>
      <ThemeProvider theme={crefoTheme}>
        {/* header Grid Container */}
        <Header
          targetPlatform={verifierName}
          activeStep={0}
          showTargetPlatformLink
          showSteps={false}
          showStepper={false}
        />
        {/* Perso links (4), Unternehmensauswahl u Personenauswahl rechts (8) */}
        <Grid
          container
          direction='column'
          className={classes.outerGridContainer}
        >
          <Grid item lg={12}>
            <Typography variant='h1' component='h1' className={classes.title}>
              {t('imprint.title')}{' '}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography variant='body1' className={classes.addressText}>
              <strong>{t('imprint.addressText1')}</strong>
              <br />
              <br />
              {t('imprint.addressText2')}
              <br />
              {t('imprint.addressText3')}
              <br />
              {t('imprint.addressText4')}
              <br />
              <br />
              {t('imprint.addressText5')}
              <br />
              {t('imprint.addressText6')}
              <br />
              <br />
              <a href={`mailto:${t('imprint.addressText7')}`}>
                {t('imprint.addressText7')}
              </a>
              <br />
              <a href={`https://${t('imprint.addressText8')}`}>
                {t('imprint.addressText8')}
              </a>
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography variant='body1' className={classes.legalNormsText}>
              <strong>{t('imprint.legalNormsText1header')}</strong>
              <br />
              {t('imprint.legalNormsText1text')}
              <br />
              <strong>{t('imprint.legalNormsText2header')}</strong>
              <br />
              {t('imprint.legalNormsText2text')}
              <br />
              <strong>{t('imprint.legalNormsText3header')}</strong>
              <br />
              {t('imprint.legalNormsText3text')}
            </Typography>
          </Grid>
          <Grid item lg={12}>
            <Typography variant='body1' className={classes.signatoriesText}>
              <strong>{t('imprint.signatoriesTextheader1')}</strong>
              <br />
              {t('imprint.signatoriesTextheader2')}
            </Typography>
            <List className={classes.signatoriesTextList} variant='body1' dense>
              <ListItemText>
                {' '}
                <span className={classes.highlightedTextRegular}>-</span>{' '}
                {` ${t('imprint.signatoriesTextListItem1')}`}
              </ListItemText>
              <ListItemText>
                {' '}
                <span className={classes.highlightedTextRegular}>-</span>{' '}
                {` ${t('imprint.signatoriesTextListItem2')}`}
              </ListItemText>
              <ListItemText>
                {' '}
                <span className={classes.highlightedTextRegular}>-</span>{' '}
                {` ${t('imprint.signatoriesTextListItem3')}`}
              </ListItemText>
            </List>
            <Typography variant='body1' className={classes.signatoriesText}>
              {t('imprint.signatoriesTextTaxInfo1')}
              <br />
              {t('imprint.signatoriesTextTaxInfo2')}
              <br />
              {t('imprint.signatoriesTextTaxInfo3')}
              <br />
            </Typography>
          </Grid>
        </Grid>
      </ThemeProvider>
    </div>
  );
};
