import { useEffect } from 'react';

import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useAuth } from "react-oidc-context";

import crefoTheme from '../../CrefoTheme';
import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { CircularLoad } from '../Load/CircularLoad';

export const KeycloakPopUp = () => {
  const { t } = useTranslation();
  const auth = useAuth();
  const verifierName = useAppSelector(selectVerifierName);

  useEffect(() => {
    if (!auth.isAuthenticated) {
      auth.startSilentRenew();
    }
  }, [auth, verifierName]);

  return (
    <ThemeProvider theme={crefoTheme}>
      <CircularLoad
        loading={true}
        customMessage={t('keycloakPopUp.loadingMessage')}
      />
    </ThemeProvider>
  );
};
