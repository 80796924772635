import { getIdentProviderName, IdentProvider } from './IdentProvider';

export const IdentProviderToCardMapping = {
  [IdentProvider.ItmVideoIdent]: {
    title: getIdentProviderName(IdentProvider.ItmVideoIdent),
    subtitle: 'itmVideoIdent.subtitle',
    image: 'IDnow-logo.png',
    description: 'itmVideoIdent.description',
    entries: [
      'itmVideoIdent.entry1',
      'itmVideoIdent.entry2',
      'itmVideoIdent.entry3',
    ],
    link: 'itmVideoIdent.link',
    highlighted: false,
  },
  [IdentProvider.NectAutoIdent]: {
    title: getIdentProviderName(IdentProvider.NectAutoIdent),
    subtitle: 'nectAutoIdent.subtitle',
    image: 'nectLogo.png',
    description: 'nectAutoIdent.description',
    entries: [
      'nectAutoIdent.entry1',
      'nectAutoIdent.entry2',
      'nectAutoIdent.entry3',
    ],
    link: 'nectAutoIdent.link',
    highlighted: true,
  },
};
