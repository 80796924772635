import React, { useState, useEffect, useCallback, useRef } from 'react';

import { Typography, Grid } from '@material-ui/core';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import ClearIcon from '@material-ui/icons/Clear';
import Autocomplete from '@material-ui/lab/Autocomplete';
import match from 'autosuggest-highlight/match';
import parse from 'autosuggest-highlight/parse';
import axios from 'axios';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { companySearchFieldStyles } from '../../CrefoTheme';

export const CompanySearchField = (props) => {
  const {
    updateCompany,
    resetData,
    keepSearchPopUpOpen,
    setKeepSearchPopUpOpen,
    isLoading,
    setIsLoading,
    companies,
    clickCompany,
    chosenCompany,
    disabled,
    preloaded,
  } = props;
  const minChars = 3;
  const [inputText, setInputText] = useState('');
  const classes = companySearchFieldStyles();
  const { t } = useTranslation();
  const searchFieldRef = useRef(null);

  const keyFunction = useCallback(
    (event) => {
      if (!disabled) {
        // Escape: Remove data and input-text
        if (event.keyCode === 27) {
          setInputText('');
          resetData();
        }
      }
    },
    [disabled, resetData]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyFunction, false);

    return () => {
      document.removeEventListener('keydown', keyFunction, false);
    };
  }, [disabled, keyFunction]);

  const onInputChange = (newInputValue) => {
    if (chosenCompany?.crefoId && newInputValue !== chosenCompany?.legalName) {
      resetData();
    }

    // Autocomplete event caches the last selection and tries to set the selection value as input
    // if you click out of an unsuccessful search
    // If clause prevents that from happening if the company is not selected anymore
    if (
      newInputValue !== '...---notYourCompany...---' &&
      (newInputValue.length - inputText.length > 1 ||
        inputText !== chosenCompany?.legalName ||
        newInputValue !== chosenCompany?.legalName)
    ) {
      setInputText(newInputValue);
    }
  };

  useEffect(() => {
    if (chosenCompany?.crefoId) {
      setInputText(chosenCompany?.legalName);
    }
  }, [chosenCompany]);

  useEffect(() => {
    // only fetch data if inputlength is sufficient and if there is no valid selected company
    // yet. checking for valid selected company avoids fetching data again after company selection
    if (
      inputText.length >= minChars &&
      chosenCompany?.legalName !== inputText &&
      !chosenCompany?.crefonummer
    ) {
      const { cancel, token } = axios.CancelToken.source();
      const timeOutId = setTimeout(() => {
        setIsLoading(true);
        updateCompany(inputText, token);
      }, 200);
      return () => cancel('No longer latest query') || clearTimeout(timeOutId);
    }
  }, [inputText, chosenCompany]); // eslint-disable-line react-hooks/exhaustive-deps

  if (disabled && preloaded) {
    return (
      <Typography style={{ textAlign: 'left', fontSize: '1.5em' }}>
        {chosenCompany?.legalName === '- Unternehmen -'
          ? ''
          : chosenCompany?.legalName}
      </Typography>
    );
  }

  return (
    <Autocomplete
      open={keepSearchPopUpOpen}
      disabled={disabled}
      autoHighlight
      // autoSelect
      // removes Autocomplete's default placeholder for the clearable icon
      // freeSolo and noOptionsText are incompatible --> no company found hint becomes not visible
      clearOnBlur={false}
      blurOnSelect
      disableClearable
      onClose={(event) => {
        // stay open if only notYourCompany option is clicked
        if (
          companies.length > 1 &&
          !event.target?.dataset?.id?.startsWith('notYourCompany')
        )
          setKeepSearchPopUpOpen(false);
      }}
      options={companies}
      loading={isLoading}
      id='companysearchfield'
      loadingText='Suche...'
      getOptionLabel={(option) => option?.legalName}
      onChange={clickCompany}
      inputValue={inputText}
      defaultValue={
        chosenCompany?.legalName !== '- Unternehmen -' ? chosenCompany : null
      }
      onInputChange={(event, newInputValue) => onInputChange(newInputValue)}
      renderInput={(params) => (
        <TextField
          {...params}
          label={
            inputText === '' &&
            chosenCompany?.legalName === '' &&
            t('companySearch.placeholder')
          }
          margin='normal'
          variant='outlined'
          placeholder={t('companySearch.placeholder')}
          fullWidth
          ref={searchFieldRef}
          InputLabelProps={{ shrink: false }}
          InputProps={{
            ...params.InputProps,
            type: 'search',
            endAdornment: (inputText !== '' ||
              chosenCompany?.legalName !== t('stdPersoCompany')) && (
              <InputAdornment position='end'>
                <ClearIcon
                  className={classes.clearIcon}
                  cursor='pointer'
                  onClick={() => {
                    resetData();
                    setInputText('');
                  }}
                />
              </InputAdornment>
            ),
          }}
        />
      )}
      // show all options --> filtering was already done in the backend
      filterOptions={(options) => options}
      // can never be true in our scenario because the search is reset every time the input is changed
      getOptionSelected={(option, value) =>
        option?.legalName === value?.legalName
      }
      renderOption={(option, { inputValue }) => {
        if (option?.legalName === '...---notYourCompany...---') {
          return (
            <Typography
              className={classes.notYourCompany}
              data-id='notYourCompany'
            >
              {t('companySearch.noOptionsText')}
              <a
                data-id='notYourCompanyLink'
                target='_top'
                rel='noopener noreferrer'
                href={`mailto:${t('companySearch.noOptionsMail')}`}
              >
                {t('companySearch.noOptionsMail')}
              </a>
            </Typography>
          );
        }
        const matches = match(option?.legalName, inputValue);
        const parts = parse(option?.legalName, matches);
        return (
          <Grid container spacing={0} className={classes.option}>
            <Grid item xs={12}>
              {parts.map((part, index) => (
                <span
                  key={index}
                  className={
                    part.highlight
                      ? classes.highlightedFontWeight
                      : classes.regularFontWeight
                  }
                >
                  {part.text}
                </span>
              ))}
            </Grid>
            <Grid item xs={12} className={classes.companyAddress}>
              {`${option.address.addressLocality}, ${option.address.streetAddress}`}
            </Grid>
          </Grid>
        );
      }}
    />
  );
};

// TODO: Use global state management like Redux or context hook to prevent prop-drilling in future.
CompanySearchField.propTypes = {
  updateCompany: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
  keepSearchPopUpOpen: PropTypes.bool.isRequired,
  setKeepSearchPopUpOpen: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  setIsLoading: PropTypes.func.isRequired,
  companies: PropTypes.arrayOf(PropTypes.shape({})),
  clickCompany: PropTypes.func.isRequired,
  chosenCompany: PropTypes.shape({}),
  disabled: PropTypes.bool.isRequired,
  preloaded: PropTypes.bool.isRequired,
};
