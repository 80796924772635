import { useState } from 'react';

import { Divider, Grid, GridSize } from '@material-ui/core';

import { BackButton } from './BackButton';
import { ContinueButton } from './ContinueButton';
import { ItemInfo } from './ItemInfo';
import { NavigationStyles } from './Navigation.styles';
import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { ReturnToTPPopUp } from '../Header/ReturnToTPPopUp';

const getButtonPosition = (
  showContinueButton: any,
  showBackButton: any,
  showItmInfo: any
): {
  continueButtonCols: boolean | GridSize;
  backButtonInfoCols: boolean | GridSize;
} => {
  let continueButtonCols: boolean | GridSize = false;
  let backButtonInfoCols: boolean | GridSize = false;

  // both buttons:
  if (showContinueButton && showBackButton) {
    continueButtonCols = 6;
    backButtonInfoCols = 6;
    // hint and continue button:
  } else if (showContinueButton && showItmInfo) {
    continueButtonCols = 4;
    backButtonInfoCols = 8;
    // only continue button:
  } else if (showContinueButton) {
    continueButtonCols = 12;
    backButtonInfoCols = false;
    // only backbutton or hint (they are never displayed together):
  } else if (showBackButton || showItmInfo) {
    continueButtonCols = false;
    backButtonInfoCols = 12;
  }

  return { continueButtonCols, backButtonInfoCols };
};

export const Navigation = ({
  onClick,
  showItmInfo,
  showBackButton,
  showContinueButton,
  loading,
  disabled,
  nextButtonText,
  nextButtonLink,
  requestResult,
  buttonWidth,
}: any) => {
  const classes = NavigationStyles();
  const [popup, setPopup] = useState(false);
  const verifierName = useAppSelector(selectVerifierName);

  // determines position of buttons and hint in navigation bar
  const { continueButtonCols, backButtonInfoCols } = getButtonPosition(
    showContinueButton,
    showBackButton,
    showItmInfo
  );

  return (
    <Grid item xs={12} md={9} className={classes.navigationWrapper}>
      <div id='navigation' className={classes.navigationContainer}>
        <Grid container className='container' justifyContent='center'>
          <Grid
            xs={12}
            item
            style={{
              height: 1,
            }}
          >
            <Divider />
          </Grid>
          <Grid
            style={{ backgroundColor: 'white' }}
            direction='row'
            xs={12}
            container
            item
          >
            <Grid
              xs={backButtonInfoCols}
              style={{ marginTop: '20px', textAlign: 'left' }}
              item
            >
              {showItmInfo ? <ItemInfo /> : null}
              {showBackButton ? <BackButton setPopup={setPopup} /> : null}
            </Grid>
            <Grid
              xs={continueButtonCols}
              className={classes.nextButtonContainer}
              item
            >
              <ContinueButton
                requestResult={requestResult}
                loading={loading}
                disabled={disabled}
                onClick={onClick}
                text={nextButtonText}
                link={nextButtonLink}
                buttonWidth={buttonWidth}
              />
            </Grid>
          </Grid>
          {/* Nichts links (9), button rechts (3) */}
        </Grid>
        {popup ? (
          <ReturnToTPPopUp
            targetplatformName={verifierName}
            onCloseCallBack={() => setPopup(false)}
          />
        ) : (
          <div />
        )}
      </div>
    </Grid>
  );
};
