import { useState, useEffect } from 'react';

import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { ApprovalCheckBox } from './ApprovalCheckBox';
import { ApproveCredentialsStyles } from './ApproveCredentials.style';
import { DisplayCredentials } from './DisplayCredentials';
import { ErrorView } from './ErrorView';
import { SendingCredentialsPopUp } from './SendingCredentialsPopUp';
import crefoTheme from '../../CrefoTheme';
import { ApiVersion } from '../../interfaces/redux-states.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectCompanyCredentials,
  selectError,
  selectFlavor,
  selectPersonCredentials, selectRequiredCredentialTypes,
  selectVerifierName,
  sendCredentials
} from '../../state/ident/identSlice';
import { isCreditscoreRequired } from '../../utils/helpers';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { CircularLoad } from '../Load/CircularLoad';
import { Navigation } from '../Navigation/Navigation';
import { Popup } from '../Popups/Popup';

export const ApproveCredentials = () => {
  const classes = ApproveCredentialsStyles();
  const { t } = useTranslation();

  const dispatch = useAppDispatch();

  const [loading, setLoading] = useState(true);
  const [loadingButton, setLoadingButton] = useState(true);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [credentialApprovalCheck, setCredentialApprovalCheck] = useState(false);
  const [error, setError] = useState(false);
  const [parentError] = useState(false);
  const [showSendCredentialsPopUp, setShowCredentialSetUp] = useState(false);

  const verifierName = useAppSelector(selectVerifierName);
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);
  const requiredCredentialTypes = useAppSelector(selectRequiredCredentialTypes);
  const apiVersion = ApiVersion.ORDER;
  const identError = useAppSelector(selectError);
  const flavor = useAppSelector(selectFlavor);

  // enable 'next' button when signatory is selected
  useEffect(() => {
    setButtonDisabled(!credentialApprovalCheck);
  }, [credentialApprovalCheck]);

  useEffect(() => {
    if (
      personCredentials.length > 0 &&
      (companyCredentials.length > 0 || flavor === 'B2C') &&
      requiredCredentialTypes
    ) {
      const missingPersonCredTypes = requiredCredentialTypes.person.filter(
        (type) => !personCredentials.map((cred) => cred.type).includes(type)
      );
      const missingCompanyCredTypes = requiredCredentialTypes.org.filter(
        (type) => !companyCredentials.map((cred) => cred.type).includes(type)
      );
      if (
        missingPersonCredTypes.length === 0 &&
        missingCompanyCredTypes.length === 0
      ) {
        setLoading(false);
        setLoadingButton(false);
      }
    }
  }, [personCredentials, companyCredentials, requiredCredentialTypes]);

  const sendCredentialsToTargetPlatform = async () => {
    dispatch(sendCredentials());
  };

  useEffect(() => {
    if (identError) {
      setLoadingButton(false);
      setShowCredentialSetUp(true);
    }
  }, [identError]);

  const CredentialList = () => {
    return (
      <Grid
        container
        direction='row'
        justifyContent='flex-start'
        alignItems='center'
        style={{ textAlign: 'left' }}
      >
        <Grid lg={12} item>
          <Typography className={classes.header} variant='h2'>
            {t('approveCredentials.header')}
          </Typography>
        </Grid>
        <Grid lg={12} item>
          <Typography className={classes.body} variant='body1'>
            {t('approveCredentials.body')}
          </Typography>
        </Grid>
        <Grid
          xs={12}
          sm={12}
          md={8}
          lg={6}
          className={classes.credentialContainer}
          item
        >
          <DisplayCredentials />
        </Grid>
        <Grid item xs={12} md={10}>
          <ApprovalCheckBox
            credentialApprovalCheck={credentialApprovalCheck}
            hostname={verifierName}
            setCredentialApprovalCheck={setCredentialApprovalCheck}
          />
        </Grid>
      </Grid>
    );
  };

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header
          targetPlatform={verifierName}
          showTargetPlatformLink
          showStepper={!!localStorage.getItem('finishedProcess')}
          activeStep={isCreditscoreRequired() ? 3 : 2}
        />
        <Grid
          id='content'
          container
          className={`container marginTop ${classes.contentContainer}`}
        >
          <Grid
            md={12}
            alignItems='flex-start'
            direction='row'
            spacing={0}
            container
            item
          >
            <Grid container item lg={12} md={12} /* Content */>
              <CircularLoad loading={loading} customMessage='' />
              {!loading && <CredentialList />}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent='center'>
          <Navigation
            onClick={sendCredentialsToTargetPlatform}
            loading={loadingButton}
            disabled={buttonDisabled}
            nextButtonText={t('approveCredentials.approveButton')}
            showContinueButton
            showBackButton
          />
          <Footer />
          {error && (
            <Popup
              title='Fehler während der Freigabe'
              content={<ErrorView targetPlatform={verifierName} />}
              onClose={() => setError(false)}
              fullScreen={false}
            />
          )}
          {parentError && (
            <Popup
              title='Fehler während der Freigabe'
              content={
                <span>
                  `Für die Verifizierung mit CrefoTrust muss das Browserfenster
                  von ${verifierName} während des gesamten Prozesses geöffnet
                  sein. Bitte starten Sie den Prozess erneut über $
                  {verifierName}`
                </span>
              }
              onClose={() => setError(false)}
              fullScreen={false}
            />
          )}
        </Grid>
        {showSendCredentialsPopUp ? (
          <SendingCredentialsPopUp
            onClose={() => {
              setShowCredentialSetUp(false);
            }}
          />
        ) : null}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
