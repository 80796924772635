
import { AddressInputField } from './AddressInputField';
import { CustomerInputData } from './customer-input.interface';
import { CredentialTypeEnum } from "../../api-clients/core-service-api-react";

interface InputFieldsProps {
  customerRequiredData: CredentialTypeEnum[];
  setCustomerInputData: (customerInputData: CustomerInputData) => void;
  isPersonRelated: boolean;
}

export const InputFields = ({
  customerRequiredData,
  setCustomerInputData,
  isPersonRelated,
}: InputFieldsProps) => {
  return (
    <>
      {customerRequiredData.map((key: CredentialTypeEnum) => {
        switch (key) {
          case CredentialTypeEnum.Address: {
            return (
              <AddressInputField
                key={key}
                setCustomerInputData={setCustomerInputData}
                isPersonRelated={isPersonRelated}
              />
            );
          }
          default: {
            break;
          }
        }
      })}
    </>
  );
};
