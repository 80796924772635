import { useEffect, useState } from 'react';

import { Typography, Paper, Divider, Tooltip } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import InfoIcon from '@material-ui/icons/Info';
import { useTranslation } from 'react-i18next';
import Typed from 'react-typed';

import {CredentialTypeEnum} from "../../api-clients/core-service-api-react";
import { identityCardStyles, crefoColors } from '../../CrefoTheme';
import { RequestResult } from '../../interfaces/request-result.interface';
import { useAppSelector } from '../../state/hooks';
import {
  selectAllRequiredPersonCredsAvailable,
  selectCompanyCredentials,
  selectFlavor,
  selectLatestRequest,
  selectPersonCredentials,
  selectRequiredAuthorizedRepresentative,
  selectRequiredCompanyCredentialTypes,
  selectRequiredPersonCredentialTypes,
} from '../../state/ident/identSlice';
import {
  selectChosenCompany,
  selectChosenSignatory,
} from '../../state/input/inputSlice';

const ContentField = ({ classes, animated, value }) => {
  if (animated) {
    return (
      <Typed
        strings={[value]}
        smartBackspace
        typeSpeed={40}
        shuffle
        showCursor={false}
      />
    );
  }
  return (
    <Typography
      variant='body1'
      className={classes.selectedText}
      style={{ color: 'white' }}
      noWrap
    >
      {value}
    </Typography>
  );
};

const Credentials = ({ classes, svg, title, details, type }) => (
  <Grid
    container
    className={classes.belowIdCard}
    style={{ marginTop: 10 }}
    spacing={1}
    alignItems='center'
  >
    <Grid item xs={3}>
      <img src={`${svg}.svg`} alt='identity' id='below-id-card' />
    </Grid>
    <Grid item xs={9}>
      <Typography
        variant='body1'
        className={classes.credentialDetails}
        style={
          svg.includes('insufficient')
            ? { color: crefoColors['c-warning'], fontSize: 15 }
            : svg.includes('verified')
            ? { color: crefoColors['c-grau-2'], fontSize: 15 }
            : svg.includes('failed')
            ? { color: crefoColors['c-error-2'], fontSize: 15 }
            : { fontSize: 15 }
        }
        align='left'
      >
        {title}
      </Typography>
      {details && (
        <Typography className={classes.credentialDetails} align='left'>
          {details}
        </Typography>
      )}
      <Typography className={classes.menloText} align='left'>
        {type}
      </Typography>
    </Grid>
  </Grid>
);

export const IdentityCard = ({ animated, idResult, cwcResult = null }) => {
  const classes = identityCardStyles();
  const { t } = useTranslation();
  const chosenCompany = useAppSelector(selectChosenCompany);
  const chosenSignatory = useAppSelector(selectChosenSignatory);
  const requiredPersonCredentials = useAppSelector(
    selectRequiredPersonCredentialTypes
  );
  const requiredCompanyCredentials = useAppSelector(
    selectRequiredCompanyCredentialTypes
  );
  const requiredAuthorizedRepresentative = useAppSelector(
    selectRequiredAuthorizedRepresentative
  );
  const flavor = useAppSelector(selectFlavor);

  const latestRequest = useAppSelector(selectLatestRequest);
  const [personName, setPersonName] = useState('- Name -');
  const [companyName, setCompanyName] = useState('- Unternehmen -');
  const [otherPersonVerified, setOtherPersonVerified] = useState(false);

  const personCrendentialDetailsCreator = () => {
    const message = [];
    if (requiredPersonCredentials?.includes('name')) message.push('Name');
    if (requiredPersonCredentials?.includes('address')) message.push('Adresse');
    if (requiredPersonCredentials?.includes('birthdate'))
      message.push('Geburtstag');
    if (requiredPersonCredentials?.includes('nationality'))
      message.push('Nationalität');
    return message.join(', ');
  };

  const allRequiredPersonCredsAvailable = useAppSelector(
    selectAllRequiredPersonCredsAvailable
  );
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);

  const getPersonCredentialsSvg = (idResult) => {
    switch (idResult) {
      case undefined: {
        if (allRequiredPersonCredsAvailable) {
          return 'verified-identity';
        }
        return 'requested-identity';
      }
      case RequestResult.SUCCESS:
      case RequestResult.CUSTOMER_INPUT:
        return 'verified-identity';
      case RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE:
        return 'verified-identity';
      case RequestResult.CREDENTIALS_INSUFFICIENT:
        return 'verified-insufficient-identity';
      default:
        return 'failed-identity';
    }
  };

  const getCompanyCredentialsSvg = (idResult) => {
    switch (idResult) {
      case RequestResult.SUCCESS:
        return 'verified-representer';
      case RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE:
        return 'failed-representer';
      case RequestResult.CREDENTIALS_INSUFFICIENT:
        return 'verified-insufficient-identity';
      case 'CUSTOMER_INPUT':
      default: {
        if (
          requiredAuthorizedRepresentative &&
          personCredentials.find(
            (cred) => cred.type === CredentialTypeEnum.AuthorizedRepresentative
          )
        ) {
          return 'verified-representer';
        }
        return 'requested-representer';
      }
    }
  };

  useEffect(() => {
    const nameCredential = personCredentials.find(
      (cred) => cred.type === CredentialTypeEnum.Name
    );
    if (nameCredential) {
      if (
        chosenSignatory &&
        (nameCredential.payload?.givenName?.toLowerCase() !==
          chosenSignatory?.givenName?.toLowerCase() ||
          nameCredential.payload?.familyName?.toLowerCase() !==
            chosenSignatory?.familyName?.toLowerCase())
      ) {
        setOtherPersonVerified(true);
      }
      setPersonName(
        `${nameCredential.payload?.givenName} ${nameCredential.payload?.familyName}`
      );
    } else if (chosenSignatory?.givenName) {
      setPersonName(
        `${chosenSignatory.givenName} ${chosenSignatory.familyName}`
      );
    } else {
      setPersonName(`${t('stdPersonName.first')}${t('stdPersonName.last')}`);
    }
  }, [personCredentials, chosenSignatory, latestRequest]);

  useEffect(() => {
    const nameCredential = companyCredentials.find(
      (cred) => cred.type === CredentialTypeEnum.LegalName
    );
    if (nameCredential) {
      setCompanyName(nameCredential.payload.legalName);
    } else if (chosenCompany) {
      setCompanyName(chosenCompany.legalName);
    } else {
      setCompanyName(t('stdPersoCompany'));
    }
  }, [companyCredentials, chosenCompany]);

  return (
    <Grid id='perso' container>
      <Grid container item justifyContent='flex-end'>
        <Paper
          elevation={3}
          className={classes.idCardPaper}
          style={{ backgroundImage: "url('card-with-branding.svg')" }}
        >
          <Grid container item justifyContent='flex-start'>
            <Typography className={classes.idCardTitle}>
              {t('identityCard.title')}
            </Typography>
          </Grid>
          <Grid
            container
            style={{ paddingLeft: 10 }}
            justifyContent='flex-start'
            spacing={1}
          >
            {flavor === 'B2B' && (
              <Grid
                container
                item
                justifyContent='flex-start'
                alignItems='center'
                className={classes.cardItem}
              >
                <Grid container item xs={3}>
                  <img src='company.svg' alt='user' />
                </Grid>
                <Grid container item xs={9} wrap='nowrap'>
                  {/* zeroMinWidth and noWrap dynamically shorten the typography content */}
                  <Grid item zeroMinWidth>
                    <ContentField
                      t={t}
                      classes={classes}
                      animated={animated}
                      value={companyName}
                      field='company'
                    />
                  </Grid>
                </Grid>
              </Grid>
            )}
            <Grid
              container
              item
              justifyContent={flavor === 'B2C' ? 'space-around' : 'flex-start'}
              alignItems='center'
              direction={flavor === 'B2C' ? 'column' : undefined}
              className={flavor === 'B2C' ? classes.cardItem : ''}
            >
              <Grid container item xs={3}>
                <img src='user.svg' alt='user' />
              </Grid>
              <Grid container item xs={9} wrap='nowrap'>
                {/* zeroMinWidth and noWrap dynamically shorten the typography content */}
                <Grid
                  container
                  item
                  zeroMinWidth
                  direction={flavor === 'B2C' ? 'column' : undefined}
                  justifyContent={flavor === 'B2C' ? 'space-around' : undefined}
                >
                  <ContentField
                    t={t}
                    classes={classes}
                    animated={animated}
                    value={personName}
                    field='signatory'
                  />
                </Grid>
              </Grid>
              {otherPersonVerified && flavor === 'B2B' && (
                <Grid
                  container
                  item
                  alignItems='center'
                  style={{ color: 'white' }}
                >
                  <Grid item xs={12}>
                    <Tooltip
                      title={t('identityCard.verifiedPersonRequestMismatch')}
                    >
                      <div>
                        <InfoIcon style={{ margin: '5px' }} />
                      </div>
                    </Tooltip>
                  </Grid>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Paper>
      </Grid>
      <Grid container justifyContent='flex-end'>
        <Grid
          container
          className={classes.belowIdCard}
          style={{ marginTop: 10 }}
          spacing={1}
          alignItems='center'
        >
          <Grid container item xs={3} justifyContent='center'>
            <Divider
              orientation='vertical'
              className={classes.idDivider}
              style={
                idResult === RequestResult.SUCCESS
                  ? { backgroundColor: crefoColors['c-s-3'] }
                  : undefined
              }
            />
          </Grid>
        </Grid>
        <Credentials
          classes={classes}
          svg={getPersonCredentialsSvg(idResult)}
          title={t('identityCard.identity')}
          details={personCrendentialDetailsCreator()}
          type={t('identityCard.personRelated')}
        />
        {(requiredAuthorizedRepresentative ||
          requiredCompanyCredentials?.includes('legalName')) && (
          <Credentials
            classes={classes}
            svg={getCompanyCredentialsSvg(idResult)}
            title={t('identityCard.signatory')}
            type={t('identityCard.personRelated')}
            success
          />
        )}
      </Grid>
    </Grid>
  );
};
