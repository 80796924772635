import {
  ChosenCompany,
  ChosenSignatory,
} from './request.interface';
import {Signatory} from "./signatories";
import {
  Company,
  Context,
  HolderStateData,
  HolderStateMeta,
  RequiredCredentials,
  RoutingState,
  OrderAuthRedirects,
} from "../api-clients/core-service-api-react";
import { AppDispatch, RootState } from '../state/store';

export enum ReduxStateStatus {
  INITIAL = 'initial',
  LOADING = 'loading',
  SUCCESS = 'success',
  ERROR = 'error',
}

export interface IdentState {
  context?: Context;
  data?: HolderStateData;
  meta?: HolderStateMeta;
  routing?: RoutingState;
  redirects?: OrderAuthRedirects;
  oidcConfig?: {
    authority: string;
    client_id: string;
    redirect_uri: string;
    response_mode: string;
    response_type: string;
    scope: string;
    extraQueryParams: {
      prk: string;
      title: string;
      crefoNummer?: string;
    }
  };
  status: ReduxStateStatus;
}

export enum InputStatus {
  SIGNATORY_CHOSEN = 'SIGNATORY_CHOSEN',
  COMPANY_CHOSEN = 'COMPANY_CHOSEN',
  INPUT_INITIAL = 'INPUT_INITIAL',
  RESET = 'RESET',
}

export interface InputState {
  chosenSignatory?: ChosenSignatory;
  chosenCompany?: Company;
  companies: Company[];
  signatories: Signatory[];
  status: ReduxStateStatus;
  manualReVerification: boolean;
  manualCompanyAddressCorrection: boolean;
  submitted: boolean;
  inputStatus: InputStatus;
}

export enum ApiVersion {
  SDK,
  ORDER,
}

export interface TargetPlatformState {
  order?: {
    orderId?: string;
  };
  isMatchingCrefoNummer?: boolean;
  verifierName?: string;
  requiredCredentials?: RequiredCredentials;
  status: ReduxStateStatus;
}

export const identSliceName = 'ident';
export const inputSliceName = 'input';
export const targetPlatformSliceName = 'targetPlatform';

export interface ThunkApi {
  state: RootState;
  dispatch: AppDispatch;
}
