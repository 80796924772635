export const AddressBuilder = (address) => {
  let addressText = `${address.streetAddress},  ${address.postalCode} ${address.addressLocality}`;
  if (address.addressCountry) {
    addressText += ` (${address.addressCountry})`;
  }
  return addressText;
};

export const DateBuilder = (date) => {
  const parts = date.split('-');
  if (parts.length === 1) {
    return parts[0];
  }
  const jsDate = new Date(parts[0], parts[1] - 1, parts[2]);
  const germanDate = jsDate.toLocaleDateString('de-DE');
  return germanDate;
};
