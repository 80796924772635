import { Grid, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import InfoIcon from '../../assets/images/icons/InfoIcon.svg';

export const ItemInfo = () => {
  const { t } = useTranslation();
  return (
    <Grid
      container
      direction='row'
      justifyContent='flex-start'
      alignItems='flex-start'
    >
      <Grid style={{ marginTop: '10px' }} xs={1} item>
        <img alt='info-icon' src={InfoIcon} />
      </Grid>
      <Grid xs={11} item>
        <Typography>{t('itemInfo')}</Typography>
      </Grid>
    </Grid>
  );
};
