import React, {
  Fragment,
  useState,
  useLayoutEffect,
  useEffect,
  useRef,
} from 'react';

import {
  Grid,
  Typography,
  Link,
  ThemeProvider,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import dataagreement from '../../assets/pdf/dataagreement.pdf';
import termsandconditions from '../../assets/pdf/termsandconditions.pdf';
import crefoTheme, { crefoColors, defaultTheme } from '../../CrefoTheme';

const footerStyles = makeStyles({
  divider: {
    width: '100%',
    backgroundColor: crefoColors['c-grau'],
    marginBottom: '10px',
    height: '0.5px',
  },
  container: {
    [defaultTheme.breakpoints.up('md')]: {
      position: 'fixed',
      bottom: 10,
      backgroundColor: 'white',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      width: '100%',
      paddingTop: 10,
    },
  },
  small: {
    paddingTop: '14px !important',
    [defaultTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  left: {
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      paddingBottom: '5px!important',
    },
    color: crefoColors.primary,
  },
  leftDivider: {
    color: crefoColors['c-grau-3'],
  },
  right: {
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      paddingTop: '0!important',
      width: '100%',
      textAlign: 'center',
    },
  },
  textRight: {
    color: crefoColors['c-grau-3'],
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: '10px',
    },
  },
});

export const Footer = ({ calledFromEnrollment = false, width = 0 }) => {
  const classes = footerStyles();
  const { t } = useTranslation();
  const history = useNavigate();
  const [path, setPath] = useState(history.location?.pathname);
  useEffect(() => {
    setPath(history.location?.pathname);
  }, [history]);

  const firstUpdate = useRef(true);
  useLayoutEffect(() => {
    if (firstUpdate.current) {
      firstUpdate.current = false;
      setTimeout(() => {}, 100);
    }
  });

  // enrollment uses the footer in an individual layout
  // FIXME: the parent component should be decide to render the footer based on the path
  if (
    !calledFromEnrollment &&
    (path === '/login' || path === '/register' || path === '/')
  ) {
    return <></>;
  }
  return (
    <>
      <ThemeProvider theme={crefoTheme}>
        <Grid
          container
          id='footer'
          className={`${classes.container} container`}
          style={width > 0 ? { width } : undefined}
        >
          <Grid container spacing={0} style={{ backgroundColor: '#FFFFFF' }}>
            <Grid
              item
              sm={12}
              md={5}
              lg={6}
              className={calledFromEnrollment ? classes.small : classes.left}
            >
              <Link
                href='https://www.creditreform.de/impressum'
                target='_blank'
                className={classes.left}
              >
                {t('footer.imprint')}
              </Link>
              <span className={classes.leftDivider}> | </span>
              <Link
                href={dataagreement}
                target='_blank'
                className={classes.left}
              >
                {t('footer.dataprivacypolicy')}
              </Link>
              <span className={classes.leftDivider}> | </span>
              <Link
                href={termsandconditions}
                target='_blank'
                className={classes.left}
              >
                {t('footer.termsAndConditions')}
              </Link>
            </Grid>
            <Grid
              item
              sm={12}
              md={7}
              lg={6}
              className={calledFromEnrollment ? classes.small : classes.right}
            >
              <Typography className={classes.textRight}>
                {t('footer.textRight')}
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </ThemeProvider>
    </>
  );
};
