import { useState, useEffect } from 'react';

import { Grid, Typography, Hidden } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';

import { CredentialsInsufficientStyles } from './CredentialsInsufficient.style';
import { IdentProvider } from './IdentProvider';
import { InsufficientMethods } from './InsufficientMethods';
import { CredentialTypeEnum } from "../../api-clients/core-service-api-react";
import crefoTheme from '../../CrefoTheme';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectAcceptedIdMethods,
  selectCompanyCredentials,
  selectFlavor,
  selectPersonCredentials,
  selectRequestsOpen,
  selectRequiredCredentialTypes,
  selectVerifierName,
  updateIdentState,
} from '../../state/ident/identSlice';
import {
  getSignatories,
  goToIdentService,
  selectSignatories,
  updateChosenCompany,
  updateChosenSignatory,
} from '../../state/input/inputSlice';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { IdentityCard } from '../IdentityCard/IdentityCard';
import { IdentProviderCard } from '../IdentProviderCard/IdentProviderCard';
import { Navigation } from '../Navigation/Navigation';
import { MobilePopOver } from '../Popups/MobilePopOver';
import { Popup } from '../Popups/Popup';
import { IdentProviderToCardMapping } from '../SelectIdentProvider/IdentProviderToCardMapping';
import { SupportedDocs } from '../SelectIdentProvider/SupportedDocs';

export const CredentialsInsufficient = (props) => {
  const dispatch = useAppDispatch();
  const classes = CredentialsInsufficientStyles();
  const { t } = useTranslation();

  const [insufficientIdMethods, setInsufficientIdMethods] = useState([]);
  const [selectedIdentProvider, setSelectedIdentProvider] = useState('');
  const [loading, setLoading] = useState(false);
  const [help, setHelp] = useState(false);
  const [showSupportedDocs, setShowSupportedDocs] = useState('');

  const verifierName = useAppSelector(selectVerifierName);
  const acceptedIdMethods = useAppSelector(selectAcceptedIdMethods);
  const personCredentials = useAppSelector(selectPersonCredentials);
  const companyCredentials = useAppSelector(selectCompanyCredentials);
  const requiredCredentialTypes = useAppSelector(selectRequiredCredentialTypes);
  const openRequests = useAppSelector(selectRequestsOpen);
  const setChosenCompany = (company) => dispatch(updateChosenCompany(company));
  const setChosenSignatory = (signatory) =>
    dispatch(updateChosenSignatory(signatory));
  const signatories = useAppSelector(selectSignatories);
  const flavor = useAppSelector(selectFlavor);
  const [identProviderUrl, setIdentProviderUrl] = useState('');

  useEffect(() => {
    if (selectedIdentProvider) {
      createRequest();
    }
  }, [selectedIdentProvider]);

  useEffect(() => {
    if (
      openRequests &&
      openRequests.length > 0 &&
      openRequests.find((req) => req.dataSource === selectedIdentProvider)
    ) {
      const personCrefoId = personCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.CrefoId
      )?.payload?.crefoId;
      const selectedRequest = openRequests.find(
        (req) =>
          req.dataSource === selectedIdentProvider &&
          (req.payload.crefoId === personCrefoId || flavor === 'B2C')
      );
      if (selectedRequest) {
        dispatch(goToIdentService());
        setLoading(false);
        setIdentProviderUrl(selectedRequest.actions[0].value.TargetURL);
      } else {
        console.log(
          `Request should have been created but no request existing (yet?) or chosen signatory and person in request mismatch. Maybe mocking is enabled to automatically set person in request to mocked data?`
        );
      }
    }
  }, [openRequests, selectedIdentProvider, personCredentials]);

  const createRequest = () => {
    setLoading(true);
    dispatch(
      updateIdentState({
        customerProvidedData: {},
        provider: selectedIdentProvider,
      })
    );
  };

  useEffect(() => {
    const companyCrefoId = companyCredentials?.find(
      (cred) => cred.type === CredentialTypeEnum.CrefoId
    )?.payload?.crefoId;
    dispatch(getSignatories(companyCrefoId));
  }, [companyCredentials]);

  useEffect(() => {
    if (signatories) {
      const givenName = personCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.Name
      )?.payload?.givenName;
      const familyName = personCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.Name
      )?.payload?.familyName;
      const birthDate = personCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.Birthdate
      )?.payload?.birthdate;
      const personCrefoId = personCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.CrefoId
      )?.payload?.crefoId;
      const personAddress = signatories.find(
        (sig) => sig.crefoId === personCrefoId
      )?.address;

      const legalName = companyCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.LegalName
      )?.payload?.legalName;
      const companyCrefoId = companyCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.CrefoId
      )?.payload?.crefoId;
      const companyAddress = companyCredentials?.find(
        (cred) => cred.type === CredentialTypeEnum.Address
      )?.payload?.address;

      setChosenCompany({
        legalName,
        crefoId: companyCrefoId,
        address: companyAddress,
      });

      setChosenSignatory({
        givenName,
        familyName,
        birthDate,
        crefoId: personCrefoId,
        address: personAddress,
      });
      const internalIdMethods = [
        IdentProvider.DID,
        IdentProvider.CREFO_FIRMENWISSEN,
      ];
      const filteredIdMethods = personCredentials
        .filter((cred) => requiredCredentialTypes.person.includes(cred.type))
        .filter(
          (cred) =>
            !acceptedIdMethods.includes(cred.dataSource) &&
            !internalIdMethods.includes(cred.dataSource)
        )
        .map((cred) => cred.dataSource);
      setInsufficientIdMethods(Array.from(new Set(filteredIdMethods)));
    }
  }, [
    signatories,
    companyCredentials,
    personCredentials,
    requiredCredentialTypes,
  ]);

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header activeStep={0} showTargetPlatformLink showStepper />
        <Grid
          container
          id='content'
          className={`container marginTop ${classes.contentContainer}`}
        >
          <Grid
            lg={12}
            md={12}
            alignItems='flex-start'
            direction='row'
            spacing={0}
            container
            item
            className={classes.mobileContent}
          >
            <Grid container item lg={8} md={12}>
              <Grid item md={12} lg={12}>
                <Grid item md={12} lg={12} className={classes.margin}>
                  <Typography
                    variant='h2'
                    component='h2'
                    align='left'
                    style={{ marginTop: '30px' }}
                  >
                    {t('credentialsInsufficient.header')}
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: '20px' }} item md={12}>
                  <Typography align='left'>
                    {t('credentialsInsufficient.body1')}
                    {insufficientIdMethods.length > 0 && (
                      <InsufficientMethods
                        insufficientIdMethods={insufficientIdMethods}
                      />
                    )}
                    {t('credentialsInsufficient.body2')}
                    <b>{verifierName ?? 'ihren Anbieter'}</b>
                    {t('credentialsInsufficient.body3')}
                  </Typography>
                </Grid>
                <Grid
                  container
                  item
                  sm={12}
                  md={12}
                  lg={12}
                  style={{
                    marginTop: 20,
                  }}
                  spacing={2}
                >
                  {acceptedIdMethods &&
                    acceptedIdMethods.map((method) => {
                      const mapping = IdentProviderToCardMapping[method];
                      return (
                        <IdentProviderCard
                          key={method}
                          identProvider={method}
                          title={mapping.title}
                          subtitle={t(mapping.subtitle)}
                          image={mapping.image}
                          description={t(mapping.description)}
                          entries={mapping.entries}
                          link={t(mapping.link)}
                          isSelected={selectedIdentProvider === method}
                          setSelectedIdentProvider={setSelectedIdentProvider}
                          setShowSupportedDocs={setShowSupportedDocs}
                        />
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
            <Grid container item lg={4} justifyContent='flex-end'>
              <Hidden mdDown>
                <Grid item>
                  <IdentityCard idResult='CREDENTIALS_INSUFFICIENT' />
                </Grid>
                <Hidden only={['lg', 'sm']}>
                  <Grid item md={6} lg={4}>
                    <Typography variant='body1' align='justify' paragraph>
                      {t('showSearch.belowPersoText')}
                    </Typography>
                  </Grid>
                </Hidden>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent='center'>
          <Hidden mdDown>
            <Navigation
              onClick={() => document.location.assign(identProviderUrl)}
              showItmInfo
              loading={loading}
              disabled={!identProviderUrl}
              nextButtonText={t('credentialsInsufficient.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Hidden lgUp>
            <Navigation
              onClick={() => document.location.assign(identProviderUrl)}
              showItmInfo={false}
              loading={loading}
              disabled={!identProviderUrl}
              nextButtonText={t('showSearch.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Footer />
        </Grid>
        {help && (
          <MobilePopOver
            onClickCloseButton={() => {
              setHelp(false);
            }}
            open={help}
          />
        )}
        {showSupportedDocs && (
          <Popup
            title={t('supportedDocs.title')}
            customWidth={'auto'}
            content={<SupportedDocs identProvider={showSupportedDocs} />}
            onClose={() => {
              setShowSupportedDocs(null);
            }}
            fullScreen={false}
          />
        )}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
