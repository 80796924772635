import {
  createAsyncThunk,
  createSelector,
  createSlice,
  PayloadAction,
} from '@reduxjs/toolkit';

import { getVerifierNameByOrderId } from './targetPlatformApi';
import {
  RequiredCredentialsOrgEnum,
  RequiredCredentialsPersonEnum
} from "../../api-clients/core-service-api-react";
import {
  ReduxStateStatus,
  targetPlatformSliceName,
  TargetPlatformState,
} from '../../interfaces/redux-states.interface';
import {getQueryParam} from "../../utils/helpers";
import {CrefoThunkConfig, RootState} from '../store';

const initialState: TargetPlatformState = {
  requiredCredentials: {
    person: [],
    org: [],
    authorizedRepresentative: false,
  },
  status: ReduxStateStatus.INITIAL,
};

export const initialSetupTargetPlatformOrder = createAsyncThunk<
  { orderId: string; verifierName: string },
  string,
  CrefoThunkConfig
>(
  `${targetPlatformSliceName}/initialSetupTargetPlatformOrder`,
  async (): Promise<{ orderId: string; verifierName: string }> => {
    const orderId = getQueryParam(window.location.search, 'prt') ?? '';
    if (!orderId) {
        console.error('No orderId provided')
      throw Error('No orderId provided');
    }

    const verifierName = await getVerifierNameByOrderId(orderId);
    return { orderId, verifierName };
  }
);

export const selectIsInitialized = createSelector(
    (state: RootState) => ({
      orderId: state.targetPlatform.order?.orderId,
      requiredCredentials: state.targetPlatform.requiredCredentials,
    }),
    ({ orderId, requiredCredentials }): boolean => {
      return !!orderId && !!requiredCredentials;
    }
);

export const targetPlatformSlice = createSlice({
  name: targetPlatformSliceName,
  initialState,
  reducers: {
    updateTargetPlatform: (
      state,
      action: PayloadAction<{
        orderId: string;
        verifierName?: string;
        requiredCredentials?: {
          person: RequiredCredentialsPersonEnum[];
          org: RequiredCredentialsOrgEnum[];
          authorizedRepresentative?: boolean;
        };
      }>
    ) => {
      if (action.payload.verifierName !== '') {
        state.verifierName = action.payload.verifierName;
      }
      state.requiredCredentials = action.payload.requiredCredentials;
      state.order = {
          orderId: action.payload.orderId,
      };
      state.status = ReduxStateStatus.SUCCESS;
    },
  },

  extraReducers: (builder) => {
    builder
      .addCase(initialSetupTargetPlatformOrder.pending, (state) => {
        state.status = ReduxStateStatus.LOADING;
      })
      .addCase(initialSetupTargetPlatformOrder.fulfilled, (state, action) => {
        state.order = {
          orderId: action.payload.orderId,
        };
        state.verifierName = action.payload.verifierName;
        state.status = ReduxStateStatus.SUCCESS;
      })
      .addCase(initialSetupTargetPlatformOrder.rejected, (state) => {
        state.status = ReduxStateStatus.ERROR;
      })
        .addCase('RESET_STATE', (state) => {
            state = initialState;
        })
    ;
  },
});
export const { updateTargetPlatform } =
    targetPlatformSlice.actions;
export const targetPlatformReducer = targetPlatformSlice.reducer;
