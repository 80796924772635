import {useEffect, useRef, useState} from 'react';

import { Typography, CircularProgress, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Company,
  HolderCredentialState,
  PersonRequest,
} from "../../api-clients/core-service-api-react";
import { waitForVerificationStyles } from '../../CrefoTheme';
import {Signatory} from "../../interfaces/signatories";
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  selectCompanyFromCompanyCredentials,
  selectIdent,
  selectManagerFromPersonCredentials,
} from '../../state/ident/identSlice';
import {
  selectChosenSignatory,
  updateChosenCompany,
  updateChosenSignatory,
} from '../../state/input/inputSlice';

interface Props {
  onFinishCallback: (req: PersonRequest | undefined, credentialState?: HolderCredentialState) => Promise<void>;
  openSecondaryInfo: () => void;
}

export const WaitForVerification = ({
  onFinishCallback,
  openSecondaryInfo,
}: Props) => {
  const { t } = useTranslation();
  const classes = waitForVerificationStyles();
  const dispatch = useAppDispatch();
  const chosenSignatory = useAppSelector(selectChosenSignatory);
  const setChosenCompany = (company: Company) =>
    dispatch(updateChosenCompany(company));
  const setChosenSignatory = (signatory: Signatory) =>
    dispatch(updateChosenSignatory(signatory));

  const getSignatoryFromPersonCredentials = useAppSelector(selectManagerFromPersonCredentials);
  const getCompanyFromCompanyCredentials = useAppSelector(selectCompanyFromCompanyCredentials);

  const timerIdRef = useRef<any>(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);
  const identState = useAppSelector(selectIdent);
  const isFirstRun = useRef(true);

  useEffect(() => {
    const pollingCallback = () => {
      dispatch(getIdentState());
    };

    const startPolling = () => {
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPollingEnabled) {
      startPolling()
    }

    if (!isPollingEnabled) {
      stopPolling()
    }

    return () => {
      stopPolling();
    };
  }, [isPollingEnabled]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const credentialState = identState.meta?.credentialState;

    if (credentialState?.personCredentialState?.fulfilled) {
      setChosenSignatory(getSignatoryFromPersonCredentials);
    }

    if (credentialState?.companyCredentialState?.fulfilled) {
      setChosenCompany(getCompanyFromCompanyCredentials);
    }

    const userReceivedAllRequiredCredentialTypes =
        credentialState?.personCredentialState?.fulfilled &&
        credentialState?.representationCredentialState?.fulfilled &&
        credentialState?.companyCredentialState?.fulfilled;

    if (userReceivedAllRequiredCredentialTypes) {
      onFinishCallback(undefined, credentialState)
      setIsPollingEnabled(false);
      return;
    }

    const latestPersonRequest = identState.data?.requests[0]

    if (latestPersonRequest) {
      if (
          chosenSignatory?.givenName !== latestPersonRequest?.payload?.givenName ||
          chosenSignatory?.familyName !== latestPersonRequest?.payload?.familyName
      ) {
        setChosenSignatory({ ...latestPersonRequest.payload, id: '', isAuthorizedRepresentative: false, validSignatory: true });
      }
    }

    if (!identState.meta?.link) {
      onFinishCallback(latestPersonRequest, credentialState);
      setIsPollingEnabled(false);
    }

  }, [
      identState,
  ]);

  return (
    <>
      <div className={classes.container}>
        <h2 className={classes.headline}>{t('waitForVerification.title')}</h2>
        <Typography className={classes.text} align='left'>
          {t('waitForVerification.text')}
        </Typography>
      </div>
      <Hidden lgUp>
        <Typography className={classes.secondaryInfo}>
          <a href='#' onClick={openSecondaryInfo}>
            {t('waitForVerification.linkText')}
          </a>
        </Typography>
      </Hidden>
      <CircularProgress size={80} className={classes.spinner} />
      <Hidden mdDown>
        <Typography style={{ position: 'absolute', bottom: 200 }}>
          <a href='#' onClick={openSecondaryInfo}>
            {t('waitForVerification.linkText')}
          </a>
        </Typography>
      </Hidden>
    </>
  );
};
