import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import { ApproveCredentialsStyles } from './ApproveCredentials.style';
import { AddressBuilder, DateBuilder } from './Helpers';

export const PersonCredentialAccordion = (props) => {
  const classes = ApproveCredentialsStyles();
  const { t } = useTranslation();
  const {
    givenName,
    familyName,
    birthdate,
    personalAddress,
    nationality,
    hostname,
  } = props;
  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item lg={1}>
            <Avatar alt='identity' src='identity.svg' />
          </Grid>
          <Grid item lg={11}>
            <Typography className={classes.accordionSummaryRightColumn}>
              {`${t('approveCredentials.personText')} ${givenName || ''} ${
                familyName || ''
              }`}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Grid item lg={11}>
            <Typography
              className={classes.accordionSummaryRightColumn}
              style={{ fontWeight: 'bold' }}
            >
              {`${t(
                'approveCredentials.personCredentialAccordionText1'
              )} ${hostname} ${t(
                'approveCredentials.personCredentialAccordionText2'
              )}`}
            </Typography>
          </Grid>
          <Grid item lg={11}>
            <List
              className={classes.accordionSummaryRightColumn}
              variant='body1'
              dense
            >
              {givenName && familyName && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {' ' +
                    `${t(
                      'approveCredentials.personText'
                    )}: ${givenName} ${familyName}`}
                </ListItem>
              )}
              {birthdate && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {' ' +
                    `${t('approveCredentials.birthdateText')} ${DateBuilder(
                      birthdate
                    )}`}
                </ListItem>
              )}
              {personalAddress && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {' ' +
                    `${t(
                      'approveCredentials.addressTextUser'
                    )} ${AddressBuilder(personalAddress)}`}
                </ListItem>
              )}
              {nationality && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {`${t('approveCredentials.nationalityText')} ${nationality}`}
                </ListItem>
              )}
            </List>
          </Grid>

          <Grid item lg={11} style={{ marginTop: 20 }}>
            <p>
              <Tooltip title={t('approveCredentials.personDataWrongHint')}>
                <img
                    style={{ marginRight: '10px' }}
                    src='icons/Kontexthilfe.svg'
                    alt='context-help'
                />
              </Tooltip>
              {t('approveCredentials.personDataWrong1')}
              <a
                  href='#'
              >
                {t('approveCredentials.personDataWrongLink')}
              </a>
              {t('approveCredentials.personDataWrong2')}
            </p>
          </Grid>

        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
