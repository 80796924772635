import { Typography, Button, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { returnPopupStyles } from '../../CrefoTheme';
import { useAppDispatch } from '../../state/hooks';
import { gotoTargetPlatform } from '../../state/ident/identSlice';
import { Popup } from '../Popups/Popup';

interface ReturnToTPPopUpProps {
  onCloseCallBack: () => void;
  targetplatformName: string;
}

export const ReturnToTPPopUp = ({
  onCloseCallBack,
  targetplatformName,
}: ReturnToTPPopUpProps) => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  const Content = () => {
    const classes = returnPopupStyles();
    const text1 = t('returnPopup.text1').replace(
      '[targetPlatform]',
      targetplatformName
    );
    const text2 = t('returnPopup.text2').replace(
      '[targetPlatform]',
      targetplatformName
    );

    return (
      <div>
        <Typography>{text1}</Typography>
        <Typography style={{ marginTop: '30px' }}>{text2}</Typography>
        <div className={classes.buttonContainer} style={{ marginTop: '30px' }}>
          <Button
            className={classes.button1}
            variant='outlined'
            color='secondary'
            onClick={onCloseCallBack}
          >
            {t('returnPopup.button1')}
          </Button>
          <Button
            className={classes.button2}
            onClick={() =>
              dispatch(() => dispatch(gotoTargetPlatform('verificationResult')))
            }
          >
            {t('returnPopup.button2')}
          </Button>
        </div>
      </div>
    );
  };

  return (
    <>
      <Hidden only={['sm']}>
        <Popup
          title={t('returnPopup.title')}
          fullScreen={false}
          customWidth='600px'
          content={<Content />}
          onClose={onCloseCallBack}
        />
      </Hidden>
      <Hidden only={['lg', 'md']}>
        <Popup
          title={t('returnPopup.title')}
          fullScreen
          content={<Content />}
          onClose={onCloseCallBack}
          customWidth={'auto'}
        />
      </Hidden>
    </>
  );
};
