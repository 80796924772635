import { makeStyles } from '@material-ui/core/styles';

import { defaultTheme, crefoColors } from '../../CrefoTheme';

export const SelectIdentProviderStyles = makeStyles({
  contentContainer: {
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '0px !important',
      minHeight: 'calc(100vh - 270px)',
    },
  },
  highlightedTextRegular: {
    fontStyle: 'regular',
    color: crefoColors.primary,
  },
  card: {
    boxShadow: '0 0 13px 1px #edece5',
    border: 'solid 1px #edece5',
    marginTop: '15px',
    [defaultTheme.breakpoints.down('md')]: {
      width: '100%',
      minHeight: '56px',
    },
    [defaultTheme.breakpoints.up('lg')]: {
      width: '540px',
      minHeight: '56px',
    },
  },
  cardText: {
    textAlign: 'left',
    padding: '15px 15px 15px 15px',
  },
  header: {
    [defaultTheme.breakpoints.up('md')]: {
      marginTop: '30px',
    },
  },
  body: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '30px',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '30px',
      textAlign: 'center',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '30px',
      textAlign: 'left',
    },
  },
  credentialContainer: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '30px',
      marginLeft: '0%',
    },
    [defaultTheme.breakpoints.down('md')]: {
      marginTop: '20px',
    },
  },
  credentialListContainer: {
    [defaultTheme.breakpoints.down('md')]: {
      justifyContent: 'center',
    },
  },
  footerContainer: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '50px',
      marginLeft: '45.5%',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '20px',
      justifyContent: 'center',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
    },
  },
  footerButtonContainer: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '15px',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '5px',
      justifyContent: 'flex-end',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      width: '100%',
    },
  },
  approveButtonItem: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginLeft: '30px',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'center',
    },
  },
  approveButton: {
    [defaultTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  approveCheckBox: {
    [defaultTheme.breakpoints.up('lg')]: {
      textAlign: 'left',
      margin: '11px',
    },
    [defaultTheme.breakpoints.down('md')]: {
      textAlign: 'left',
      padding: '15px 15px 15px 15px',
    },
  },
  accordion: {
    width: '795px',
    maxWidth: 'calc(100vw - 30px)',
  },
  accordionSummary: {
    padding: 0,
  },
  accordionSummaryRightColumn: {
    marginLeft: '10px',
    textAlign: 'left',
  },
  accordionListItem: {
    paddingLeft: '0px',
  },
  accordionDetails: {
    textAlign: 'left',
    paddingTop: '0px',
    paddingRight: '30px',
    paddingBottom: '30px',
    paddingLeft: '0px',
  },
});
