import { useState, useEffect } from 'react';

import { Hidden, Grid, Typography } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useStore } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { CompanySearchStyles } from './CompanySearch.style';
import { CompanySearchField } from './CompanySearchField';
import { DisplaySignatories } from './DisplaySignatories';
import { IamSignatoryText } from './IamSignatoryText';
import crefoTheme from '../../CrefoTheme';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import {
  InputStatus,
  ReduxStateStatus,
} from '../../interfaces/redux-states.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  selectOrderPresets,
  selectVerifierName,
} from '../../state/ident/identSlice';
import {
  clearInput,
  getCompanies,
  getSignatories,
  noCompanyFound,
  selectChosenCompany,
  selectChosenSignatory,
  selectCompanies,
  selectInputStatus,
  selectSignatories,
  selectStatus,
  updateChosenCompany,
  updateChosenSignatory,
} from '../../state/input/inputSlice';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { IdentityCard } from '../IdentityCard/IdentityCard';
import { Navigation } from '../Navigation/Navigation';
import { Overlay } from '../Overlay/Overlay';
import { MobilePopOver } from '../Popups/MobilePopOver';

export const CompanySearch = (props) => {
  const verifierName = useAppSelector(selectVerifierName);
  const classes = CompanySearchStyles();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const chosenCompany = useAppSelector(selectChosenCompany);
  const chosenSignatory = useAppSelector(selectChosenSignatory);
  const setChosenCompany = (company) => dispatch(updateChosenCompany(company));
  const setChosenSignatory = (signatory) =>
    dispatch(updateChosenSignatory(signatory));

  const orderPresets = useAppSelector(selectOrderPresets);
  const companies = useAppSelector(selectCompanies);
  const signatories = useAppSelector(selectSignatories);
  const inputStatus = useAppSelector(selectInputStatus);
  const status = useAppSelector(selectStatus);
  const store = useStore();

  const { t } = useTranslation();

  const [companyDisabled, setCompanyDisabled] = useState(false);
  const [signatoryDisabled, setSignatoryDisabled] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [preloaded, setPreloaded] = useState(true);
  const [noSignatoryFound, setNoSignatoryFound] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(true);
  const [help, setHelp] = useState(false);
  const [stepNumber] = useState(0);
  const [keepSearchPopUpOpen, setKeepSearchPopUpOpen] = useState(false); // keep dropdown open if no company has been selected or there is no search result for the company
  const [isLoading, setIsLoading] = useState(false);

  const resetData = async () => {
    dispatch(clearInput());
    setButtonDisabled(true);
    setKeepSearchPopUpOpen(false);
  };

  useEffect(() => {
    if (companies.length > 0 && inputStatus === InputStatus.INPUT_INITIAL) {
      const company = companies.find((elem) => {
        if (
          (orderPresets?.forOrg?.crefoId &&
            orderPresets?.forOrg.crefoId !== elem.crefoId)
        ) {
          return false;
        }
        return true;
      });

      if (company) {
        setChosenCompany(company);
      }
    } else if (companies.length === 0 && status === ReduxStateStatus.SUCCESS) {
      dispatch(noCompanyFound());
      setIsLoading(false);
    }
  }, [companies, inputStatus]);

  useEffect(() => {
    if (signatories.length >= 1) {
      setNoSignatoryFound(false);
      if (orderPresets?.forPerson?.crefoId) {
        const newSig = signatories.find(
          (sig) => sig.crefoId === orderPresets?.forPerson?.crefoId
        );
        // TODO: Check this!
        if (newSig && (!chosenSignatory || newSig.id !== chosenSignatory.id)) {
          setChosenSignatory(newSig);
          setButtonDisabled(false);
        }
      }
    } else if (signatories.length === 0 && chosenCompany) {
      dispatch(getSignatories(chosenCompany.crefoId));
      setNoSignatoryFound(true);
    }
  }, [signatories, orderPresets]);

  useEffect(() => {
    if (chosenCompany) {
      dispatch(getSignatories(chosenCompany.crefoId));
      setInitLoading(false);
    }
  }, [chosenCompany]);

  useEffect(() => {
    if (chosenSignatory && chosenCompany) {
      setButtonDisabled(false);
    }
  }, [chosenSignatory, chosenCompany]);

  /**
   *
   * useEffect(() => {
   *     let interval;
   *     if (requestCreated) {
   *       if (openRequest) {
   *         clearInterval(interval);
   *         dispatch(goToIdentService());
   *         window.location.replace(openRequest.actions[0].value.TargetURL);
   *       } else {
   *         console.log(
   *           `Request should have been created but no request existing (yet?) or chosen signatory and person in request mismatch. Maybe mocking is enabled to automatically set person in request to mocked data?`
   *         );
   *       }
   *       interval = setInterval(() => {
   *         setInterval(dispatch(getIdentState()), 5000);
   *       }, 5000);
   *       return () => clearInterval(interval);
   *     }
   *   }, [requestCreated, openRequest]);
   * */

  useEffect(() => {
    const companyCrefoId = orderPresets?.forOrg?.crefoId;
    const preloading = async () => {
      if (!chosenCompany) {
        await preloadingCompanies();
      }
    };

    if (companyCrefoId) {
      setCompanyDisabled(orderPresets?.forOrg?.mandatory);
      if (
          inputStatus === InputStatus.INPUT_INITIAL
      ) {
        preloading();
      } else {
        setInitLoading(false);
      }
    } else {
      setCompanyDisabled(false);
      setInitLoading(false);
    }

    if (orderPresets?.forPerson?.crefoId) {
      setSignatoryDisabled(orderPresets?.forPerson?.mandatory);
    } else {
      setSignatoryDisabled(false);
    }
  }, [orderPresets, chosenCompany, inputStatus]);

  const preloadingCompanies = () => {
    const companyCrefoId = orderPresets?.forOrg?.crefoId;

    if (!companyCrefoId) {
      setInitLoading(false);
      return;
    }
    dispatch(getCompanies(companyCrefoId));
    setPreloaded(true);
  };

  // Choose company
  const clickCompany = async (e, value) => {
    if (value?.crefoId) {
      e.preventDefault();
      setChosenCompany(value); // set state with company name
      setKeepSearchPopUpOpen(false); // closes dropdown when company has been selected
      setIsLoading(true);
      dispatch(getSignatories(value.crefoId));
    }
  };

  // update company name
  const updateCompany = async (searchString) => {
    setKeepSearchPopUpOpen(true); // opens dropdown when typing is done and by that search for company is started
    dispatch(getCompanies(searchString));
  };

  // toggle for selecting signatories
  const updateChosenSignatoryById = (id) => {
    const newSignatory = signatories.find((sig) => sig.id === id);
    setChosenSignatory(newSignatory);
    setButtonDisabled(false); // enable 'next' button when signatory is selected
  };

  const proceedToIdentification = () => {
    navigate(CrefoRoute.SELECT_IDENT_PROVIDER, { state: store.getState() });
  };

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header
          targetPlatform={verifierName}
          activeStep={stepNumber}
          showTargetPlatformLink
          showSteps
          showStepper
        />
        <Grid
          container
          id='content'
          className={`container marginTop ${classes.contentContainer}`}
        >
          <Grid
            lg={12}
            md={12}
            alignItems='flex-start'
            direction='row'
            spacing={0}
            container
            item /* Content Area */
            className={classes.mobileContent}
          >
            <Grid container item lg={8} md={12} /* Content */>
              <Grid item md={12} lg={12}>
                <Grid item md={12} lg={12} className={classes.margin}>
                  <Typography variant='h2' component='h2' align='left'>
                    {t('showSearch.whichCompany')}{' '}
                    <img
                      style={{ marginBottom: '5px' }}
                      onClick={() => {
                        setHelp(true);
                      }}
                      src='icons/Kontexthilfe.svg'
                      alt='context-help'
                    />
                  </Typography>
                </Grid>
                <Grid style={{ marginTop: '20px' }} item md={12}>
                  {!companyDisabled ? (
                    <Typography align='left'>
                      {t('showSearch.whichCompanyHint')}
                    </Typography>
                  ) : (
                    <Typography align='left'>
                      <i>{verifierName}</i>
                      {t('showSearch.companyPredefinedHint')}
                    </Typography>
                  )}
                </Grid>
                <Grid item md={12} className={classes.searchMargin}>
                  <CompanySearchField
                    keepSearchPopUpOpen={keepSearchPopUpOpen} // popup which is visible when no company is found stays visible if this is true
                    setKeepSearchPopUpOpen={setKeepSearchPopUpOpen}
                    isLoading={isLoading}
                    setIsLoading={setIsLoading}
                    chosenCompany={chosenCompany}
                    companies={companies}
                    clickCompany={clickCompany}
                    updateCompany={updateCompany}
                    disabled={companyDisabled}
                    preloaded={preloaded}
                    resetData={resetData}
                    id='companysearcher'
                  />
                </Grid>
                <Grid
                    container
                    item
                    md={12}
                    lg={12}
                    className={classes.textPosition}
                    style={{
                      marginTop: 40,
                    }}
                >
                  {Array.isArray(signatories) && signatories.length > 0 && (
                      <Grid container item lg={12}>
                        <IamSignatoryText
                            numberOfSignatories={signatories.length}
                            disabled={signatoryDisabled}
                        />
                      </Grid>
                  )}
                  <Grid container item md={12} lg={12}>
                    <DisplaySignatories
                        signatories={signatories}
                        chosenSignatory={chosenSignatory}
                        updateChosenSignatory={updateChosenSignatoryById}
                        noSignatoryFound={noSignatoryFound}
                        disabled={signatoryDisabled}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
            <Grid container item lg={4} justifyContent='flex-end' /* Perso */>
              <Hidden mdDown>
                <Grid item>
                  <IdentityCard />
                </Grid>
                <Hidden only={['lg', 'sm']}>
                  <Grid item md={6} lg={4}>
                    <Typography variant='body1' align='justify' paragraph>
                      {t('showSearch.belowPersoText')}
                    </Typography>
                  </Grid>
                </Hidden>
              </Hidden>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          item
          lg={12}
          /* Navigation Container */ justifyContent='center'
        >
          <Hidden mdDown>
            <Navigation
              onClick={proceedToIdentification}
              showItmInfo={false}
              disabled={buttonDisabled}
              nextButtonText={t('showSearch.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Hidden lgUp>
            <Navigation
              onClick={proceedToIdentification}
              showItmInfo={false}
              disabled={buttonDisabled}
              nextButtonText={t('showSearch.nextButton')}
              showContinueButton
            />
          </Hidden>
          <Footer />
        </Grid>
        {initLoading ? <Overlay isLoading /> : null}
        {help ? (
          <MobilePopOver
            onClickCloseButton={() => {
              setHelp(false);
            }}
            open={help}
          />
        ) : (
          <div />
        )}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
