import { Grid, Typography } from '@material-ui/core';

export const DesktopStepper = ({ steps, active }) => {
  const finalStepStyle = {
    backgroundColor: '#009ee2',
    width: '100%',
    height: '13px',
    border: 0,
  };
  const finishedStyle = {
    ...finalStepStyle,
    width: '102%',
    // this does nothing but helps google chrome align the grid
    clipPath: 'polygon(0% 0%, 100% 0%, 100% 52%, 100% 100%, 0% 100%)',
  };
  const activeStyle = {
    ...finalStepStyle,
    width: '98%',
    clipPath: 'polygon(0% 0%, 98% 0%, 100% 52%, 98% 100%, 0% 100%)',
  };
  const inactiveStyle = {
    backgroundColor: '#dfdfdf',
    marginLeft: '-5%',
    width: '105%',
    height: '5px',
    marginTop: '1px',
    border: 0,
  };

  const size = steps.length === 3 ? 4 : 3;

  return (
    <Grid width={1} style={{ width: '100%' }} spacing={1} container>
      <Grid
        alignItems='flex-start'
        justifyContent='flex-start'
        lg={12}
        md={12}
        spacing={0}
        container
        item
        style={{ paddingBottom: 0 }}
      >
        {steps.map((value, index) => (
          <Grid direction='row' lg={size} container item key={value}>
            <Grid item>
              <Typography style={{ fontSize: 25 }} variant='body1'>
                {index + 1}
              </Typography>
            </Grid>
            <Grid item style={{ marginLeft: '10px', marginTop: '10px' }}>
              <Typography variant='body1'>{value}</Typography>
            </Grid>
          </Grid>
        ))}
      </Grid>
      <Grid
        lg={12}
        spacing={0}
        container
        item
        alignItems='center'
        style={{
          paddingTop: 0,
          paddingRight: 0,
        }}
      >
        {steps.map((value, index) => {
          if (index < active) {
            return (
              <Grid lg={size} item key={value}>
                <hr style={finishedStyle} />
              </Grid>
            );
          }
          if (index < active || active === steps.length - 1) {
            return (
              <Grid lg={size} item key={value}>
                <hr style={finalStepStyle} />
              </Grid>
            );
          }
          if (index === active) {
            return (
              <Grid lg={size} item key={value}>
                <hr style={activeStyle} />
              </Grid>
            );
          }
          return (
            <Grid lg={size} item key={value}>
              <hr style={inactiveStyle} />
            </Grid>
          );
        })}
      </Grid>
    </Grid>
  );
};
