import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { ApproveCredentialsStyles } from './ApproveCredentials.style';

export const ApprovalCheckBox = (props) => {
  const classes = ApproveCredentialsStyles();
  const { t } = useTranslation();
  const { credentialApprovalCheck, hostname, setCredentialApprovalCheck } =
    props;
  return (
    <FormControl component='fieldset' className={classes.approveCheckBox}>
      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              checkedIcon={
                <i
                  className='crefo-ui-icon icon-check icon-color-blue'
                  aria-hidden='true'
                />
              }
              checked={credentialApprovalCheck}
              color='default'
              onChange={() =>
                setCredentialApprovalCheck(!credentialApprovalCheck)
              }
              name='credentialApprovalCheck'
            />
          }
          label={
            t('approveCredentials.agreement1') +
            hostname +
            t('approveCredentials.agreement2')
          }
        />
      </FormGroup>
    </FormControl>
  );
};
