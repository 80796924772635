import {v4 as uuidv4} from "uuid";

import {Manager} from "../api-clients/core-service-api-react";
import {Signatory} from "../interfaces/signatories";

const isValidSignatory = (manager: Manager): boolean => {
    const {
        givenName,
        familyName,
        birthDate,
        crefoId,
    } = manager;

    return true; // !!givenName && !!familyName && !!birthDate && !!crefoId;
}

export const mapToSignatory = (manager: Manager): Signatory => {
    return {
        id: uuidv4(),
        validSignatory: isValidSignatory(manager),
        ...manager,
    }
}