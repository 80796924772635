import { useEffect } from 'react';

import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Avatar,
  Tooltip,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { ApproveCredentialsStyles } from './ApproveCredentials.style';
import { AddressBuilder } from './Helpers';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { ApiVersion } from '../../interfaces/redux-states.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  manuallyCorrectCompanyAddress,
  selectManualCompanyAddressCorrection,
} from '../../state/input/inputSlice';

export const OrgCredentialAccordion = (props) => {
  const classes = ApproveCredentialsStyles();
  const { t } = useTranslation();
  const { authorizedRepresentative, orgAddress, legalName, hostname } = props;
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const apiVersion = ApiVersion.ORDER;
  const manualCompanyAddressCorrection = useAppSelector(
    selectManualCompanyAddressCorrection
  );

  useEffect(() => {
    if (manualCompanyAddressCorrection) {
      navigate(CrefoRoute.CUSTOMER_INPUT_REQUIRED);
    }
  }, [manualCompanyAddressCorrection]);

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item lg={1}>
            <Avatar alt='representer' src='representer.svg' />
          </Grid>
          <Grid item lg={11}>
            <Typography className={classes.accordionSummaryRightColumn}>
              {`${t(
                'approveCredentials.authorizedRepresentativeText'
              )} ${legalName}`}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>
      <AccordionDetails className={classes.accordionDetails}>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Grid item lg={11}>
            <Typography
              className={classes.accordionSummaryRightColumn}
              style={{ fontWeight: 'bold' }}
            >
              {`${t(
                'approveCredentials.personCredentialAccordionText1'
              )} ${hostname} ${t(
                'approveCredentials.personCredentialAccordionText2'
              )}`}
            </Typography>
          </Grid>
          <Grid item lg={11}>
            <List
              className={classes.accordionSummaryRightColumn}
              variant='body1'
              dense
            >
              {authorizedRepresentative && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {' ' +
                    `${t(
                      'approveCredentials.authorizedRepresentativeText'
                    )}: ${legalName}`}
                </ListItem>
              )}
              {legalName && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {`${t('approveCredentials.legalNameText')} ${legalName}`}
                </ListItem>
              )}
              {orgAddress && (
                <ListItem className={classes.accordionListItem}>
                  <span className={classes.highlightedTextRegular}>
                    -&nbsp;
                  </span>
                  {`${t('approveCredentials.addressTextOrg')} ${AddressBuilder(
                    orgAddress
                  )}`}
                </ListItem>
              )}
            </List>
          </Grid>
          {apiVersion === ApiVersion.ORDER && (
            <Grid
              container
              direction='row'
              justifyContent='flex-end'
              alignItems='flex-end'
            >
              <Grid item lg={11} style={{ marginTop: 20 }}>
                <p>
                  <Tooltip title={t('approveCredentials.companyDataWrongHint')}>
                    <img
                      style={{ marginRight: '10px' }}
                      src='icons/Kontexthilfe.svg'
                      alt='context-help'
                    />
                  </Tooltip>
                  {t('approveCredentials.companyDataWrong1')}
                  <a
                    href='#'
                    onClick={() => {
                      dispatch(manuallyCorrectCompanyAddress(true));
                    }}
                  >
                    {t('approveCredentials.companyDataWrongLink')}
                  </a>
                  {t('approveCredentials.companyDataWrong2')}
                </p>
              </Grid>
              <Grid item lg={11} style={{ marginTop: 20 }}>
                <p>
                  {t('approveCredentials.companyRebranded1')}
                  <a href='https://meine.creditreform.de/portal/ubo/unternehmensdaten-pflegen'>
                    {t('approveCredentials.companyRebrandedLink')}
                  </a>
                  {t('approveCredentials.companyRebranded2')}
                </p>
              </Grid>
            </Grid>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
