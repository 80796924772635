import {Credential, CredentialTypeEnum} from "../api-clients/core-service-api-react";
import {RootState} from "../state/store";

export const isCreditscoreRequired = () =>
  localStorage.getItem('credentialsRequired')
    ? JSON.parse(localStorage.getItem('credentialsRequired') ?? '').includes(
        'creditscore'
      )
    : false;

export const getCredential = (credentials: Credential[], type: CredentialTypeEnum): any | undefined => {
    return credentials.find(
        (cred) => cred.type === type
    )
}

export const getCredentialPayload = (credentials: Credential[], type: CredentialTypeEnum): any | undefined => {
    return getCredential(credentials, type)?.payload;
}

export const getContext = (state: RootState): { orderId: string, did: string } => {
    const orderId = state?.targetPlatform?.order?.orderId;
    const did = state?.ident?.context?.did ?? '';

    if (!orderId) {
        throw new Error('No orderId found for state: ' + JSON.stringify(state, null, 2));
    }

    return {
        orderId,
        did,
    }
}

export const getDefaultHeaders = (token: string): Record<string, any> => ({
    Authorization: `Bearer ${token}`,
})

export const getQueryParam = (url: string, param: string) => {
    const urlParams = new URLSearchParams(url);
    return urlParams.get(param) || '';
}