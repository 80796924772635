import React, { useState, useRef } from 'react';

import {
  Grid,
  Typography,
  Collapse,
  CardActionArea,
  Hidden,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import { signatoryDropdownStyles } from '../../CrefoTheme';

export const Dropdown = (props) => {
  const classes = signatoryDropdownStyles();
  const [expandCollapse, setExpandCollapse] = useState(false);
  const [hoveredOption, setHoveredOption] = useState(-1);
  const { t } = useTranslation();
  const { chosenSignatory, signatories, updateChosenSignatory } = props;
  const dropdownRef = useRef(null);
  const executeScroll = () => dropdownRef.current.scrollIntoView();

  const orderedSignatories = [...signatories]?.sort(
    (a, b) =>
      a.givenName.charCodeAt(0) - b.givenName.charCodeAt(0) ||
      a.familyName.charCodeAt(0) - b.familyName.charCodeAt(0)
  );

  const displaySignatories = (signatories) => {
    const signatoryOptions = [];
    for (const signatory of signatories) {
      signatoryOptions.push(
        <Grid
          container
          item
          lg={12}
          className={
            hoveredOption === signatory.id
              ? classes.hoveredOptionStyle
              : classes.option
          }
          key={signatory.id}
          data-testid={`dropdown-toggle-${signatory.id}`}
          alignItems='center'
          onMouseEnter={() => setHoveredOption(signatory.id)}
          onClick={() => {
            updateChosenSignatory(signatory.id);
            setExpandCollapse(false);
            executeScroll();
          }}
        >
          <Typography className={classes.listItem}>
            {signatory.givenName}&nbsp;{signatory.familyName}
          </Typography>
        </Grid>
      );
    }
    return signatoryOptions;
  };

  // two properties are defining the look of the dropdown: hovered & collapsed
  // each of the four possible combinations results in a different look
  let dropdownStyle = classes.dropdown;
  let dropdownHovered = false;
  if (hoveredOption !== -1) {
    dropdownHovered = true;
    dropdownStyle = expandCollapse
      ? classes.activeDropdown
      : classes.hoveredDropdown;
  } else if (expandCollapse) {
    dropdownStyle = classes.openDropdown;
  }
  return (
    <Grid container className={dropdownStyle} alignItems='center'>
      <Grid
        container
        item
        lg={12}
        className={classes.selection}
        alignItems='center'
      >
        <CardActionArea
          className={classes.listItem}
          onClick={() => {
            setExpandCollapse(!expandCollapse);
            executeScroll();
          }}
          onMouseEnter={() => setHoveredOption(0)}
          onMouseLeave={() => setHoveredOption(-1)}
        >
          <Grid container item lg={12} alignItems='center'>
            <Grid item lg={10}>
              {!chosenSignatory?.id && (
                <Typography>{t('showSearch.dropdown')}</Typography>
              )}
              {chosenSignatory?.id && (
                <Typography>
                  {chosenSignatory.givenName}&nbsp;{chosenSignatory.familyName}
                </Typography>
              )}
              <Typography />
            </Grid>
            <Hidden smDown>
              <Grid container item lg={2} justifyContent='flex-end'>
                {!expandCollapse && (
                  <ExpandMore
                    className={dropdownHovered ? classes.activeIcon : ''}
                  />
                )}
                {expandCollapse && (
                  <ExpandLess
                    className={dropdownHovered ? classes.activeIcon : ''}
                  />
                )}
              </Grid>
            </Hidden>
          </Grid>
        </CardActionArea>
      </Grid>
      <Collapse
        in={expandCollapse}
        className={classes.collapse}
        timeout={0}
        ref={dropdownRef}
        onMouseLeave={() => setHoveredOption(-1)}
      >
        {displaySignatories(orderedSignatories)}
      </Collapse>
    </Grid>
  );
};
