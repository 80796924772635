export const IdentProvider = {
  ItmVideoIdent: 'itm_videoident',
  NectAutoIdent: 'nect_autoident',
  None: 'none',
};

export const getIdentProviderName = (provider: string): string => {
  switch (provider) {
    case IdentProvider.ItmVideoIdent:
      return 'Video-Ident';
    case IdentProvider.NectAutoIdent:
      return 'Selfie-Ident';
    default:
      return '';
  }
};
