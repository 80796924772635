import { useEffect, useState } from 'react';

import { Grid, Typography, Hidden, useMediaQuery } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/core/styles';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import { CustomerAddress } from './customer-address.interface';
import {
  CustomerInputData,
  CustomerProvidedData,
} from './customer-input.interface';
import { CustomerInputRequiredStyle } from './CustomerInputRequired.style';
import { InputFields } from './InputFields';
import {
  ContextPayloadDtoAffiliationEnum,
  CredentialTypeEnum,
} from '../../api-clients/core-service-api-react';
import crefoTheme, { defaultTheme } from '../../CrefoTheme';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  personWebhook,
  selectCustomerDidProvideData,
  selectRequestCustomerInputRequired,
  selectVerifierName,
  updateIdentState,
} from '../../state/ident/identSlice';
import { manuallyCorrectCompanyAddress } from '../../state/input/inputSlice';
import { DynamicHeightContainer } from '../DynamicHeightContainer/DynamicHeightContainer';
import { Footer } from '../Footer/Footer';
import { Header } from '../Header/Header';
import { IdentityCard } from '../IdentityCard/IdentityCard';
import { Navigation } from '../Navigation/Navigation';
import { MobilePopOver } from '../Popups/MobilePopOver';

export const CustomerInputRequired = () => {
  const classes = CustomerInputRequiredStyle();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const verifierName = useAppSelector(selectVerifierName);
  const requestCustomerInputRequired = useAppSelector(
    selectRequestCustomerInputRequired
  );
  const customerDidProvideData = useAppSelector(selectCustomerDidProvideData);

  const [loading, setLoading] = useState(false);
  const [help, setHelp] = useState(false);
  const [disabled, setDisabled] = useState(true);
  const [customerInputData, setCustomerInputData] = useState<CustomerInputData>(
    {
      address: {
        streetAddress: '',
        streetNumber: '',
        postalCode: '',
        addressLocality: '',
        addressCountry: '',
      },
    }
  );
  const largeScreen = useMediaQuery(defaultTheme.breakpoints.up('md'));

  useEffect(() => {
    if (
      customerInputData.address.streetAddress &&
      customerInputData.address.streetNumber &&
      customerInputData.address.postalCode &&
      customerInputData.address.addressLocality &&
      customerInputData.address.addressCountry
    ) {
      setDisabled(false);
    } else {
      setDisabled(true);
    }
  }, [customerInputData]);

  useEffect(() => {
    if (customerDidProvideData) {
      dispatch(personWebhook());
      navigate(CrefoRoute.VERIFICATION);
      const interval = setInterval(() => dispatch(getIdentState()), 2000);
      return () => clearInterval(interval);
    }
  }, [customerDidProvideData]);

  const submitCustomerProvidedData = () => {
    setLoading(true);
    const { address } = customerInputData;
    const adaptedAddress: CustomerAddress = {
      streetAddress: `${address?.streetAddress ?? ''} ${
        address?.streetNumber ?? ''
      }`,
      postalCode: address?.postalCode ?? '',
      addressLocality: address?.addressLocality ?? '',
      addressCountry: address?.addressCountry ?? '',
    };
    const customerProvidedData: CustomerProvidedData = {
      address: adaptedAddress,
    };
    if (requestCustomerInputRequired?.dataSource) {
      dispatch(
        updateIdentState({
          customerProvidedData: {
            [CredentialTypeEnum.Address]: customerProvidedData,
          },
          provider: requestCustomerInputRequired?.dataSource,
        })
      );
    } else {
      dispatch(
        updateIdentState({
          customerProvidedData: {
            [CredentialTypeEnum.Address]: customerProvidedData,
          },
          affiliation: ContextPayloadDtoAffiliationEnum.Company,
        })
      );
      dispatch(manuallyCorrectCompanyAddress(false));
      navigate(CrefoRoute.APPROVE_CREDENTIALS);
    }
  };

  const renderPersonAddressInput = () => (
    <Grid item md={12} lg={12}>
      <Grid item md={12} lg={12} className={classes.margin}>
        <Typography variant='h2' component='h2' align='left'>
          {t('customerInputRequired.header')}
        </Typography>
      </Grid>
      <Grid style={{ marginTop: '20px' }} item md={12}>
        <Typography align='left'>
          {t('customerInputRequired.body1')}
          {t('customerInputRequired.body2')}
        </Typography>
      </Grid>
      <Grid
        container
        item
        md={12}
        lg={12}
        style={{
          marginTop: 40,
        }}>
        <InputFields
          customerRequiredData={
            requestCustomerInputRequired?.customerRequiredData ?? []
          }
          setCustomerInputData={setCustomerInputData}
          isPersonRelated={true}
        />
      </Grid>
    </Grid>
  );

  const renderCompanyAddressInput = () => (
    <Grid item md={12} lg={12}>
      <Grid item md={12} lg={12} className={classes.margin}>
        <Typography variant='h2' component='h2' align='left'>
          {t('customerInputRequiredCompany.header')}
        </Typography>
      </Grid>
      <Grid style={{ marginTop: '20px' }} item md={12}>
        <Typography align='left'>
          {t('customerInputRequiredCompany.body1')}
        </Typography>
      </Grid>
      <Grid
        container
        item
        md={12}
        lg={12}
        style={{
          marginTop: 40,
        }}>
        <InputFields
          customerRequiredData={[CredentialTypeEnum.Address]}
          setCustomerInputData={setCustomerInputData}
          isPersonRelated={false}
        />
      </Grid>
    </Grid>
  );

  return (
    <ThemeProvider theme={crefoTheme}>
      <DynamicHeightContainer>
        <Header
          targetPlatform={verifierName}
          activeStep={1}
          showTargetPlatformLink
          showStepper
        />
        <Grid
          container
          id='content'
          className={`container marginTop ${classes.contentContainer}`}>
          <Grid
            lg={12}
            md={12}
            alignItems='flex-start'
            direction='row'
            spacing={0}
            container
            item>
            <Grid container item lg={8} md={12}>
              {requestCustomerInputRequired?.customerRequiredData
                ? renderPersonAddressInput()
                : renderCompanyAddressInput()}
            </Grid>
            <Grid container item lg={4} justifyContent='flex-end'>
              {largeScreen ? (
                <>
                  <Grid item>
                    <IdentityCard animated={false} idResult='CUSTOMER_INPUT' />
                  </Grid>
                  <Hidden only={['lg', 'sm']}>
                    <Grid item md={6} lg={4}>
                      <Typography variant='body1' align='justify' paragraph>
                        {t('showSearch.belowPersoText')}
                      </Typography>
                    </Grid>
                  </Hidden>
                </>
              ) : (
                ''
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid container item lg={12} justifyContent='center'>
          <Hidden mdDown>
            <Navigation
              onClick={submitCustomerProvidedData}
              showItmInfo={false}
              loading={loading}
              disabled={disabled}
              nextButtonText={t('customerInputRequired.nextButton')}
              showContinueButton
              showBackButton={false}
              nextButtonLink={null}
              requestResult={null}
              buttonWidth={null}
            />
          </Hidden>
          <Hidden lgUp>
            <Navigation
              onClick={submitCustomerProvidedData}
              showItmInfo={false}
              loading={loading}
              disabled={disabled}
              nextButtonText={t('customerInputRequired.nextButton')}
              showContinueButton
              showBackButton={false}
              nextButtonLink={null}
              requestResult={null}
              buttonWidth={null}
            />
          </Hidden>
          <Footer />
        </Grid>
        {help && (
          <MobilePopOver
            onClickCloseButton={() => {
              setHelp(false);
            }}
          />
        )}
      </DynamicHeightContainer>
    </ThemeProvider>
  );
};
