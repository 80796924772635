import {
  Configuration,
  HolderApi,
  IsSameCrefoIdResponse,
  SSOUserManagementApi,
} from '../../api-clients/core-service-api-react';
import { getContext, getDefaultHeaders } from '../../utils/helpers';
import { RootState } from '../store';

const apiConfig = new Configuration({
  basePath:
    window?._env_?.CREFO_TRUSTEE_API ??
    process.env.REACT_APP_CREFO_TRUSTEE_API ??
    '',
});

const api = new HolderApi(apiConfig);
const userManagementApi = new SSOUserManagementApi(apiConfig);

async function getVerifierNameByOrderId(orderId: string): Promise<string> {
  const result = await api.holderGatewayControllerGetVerifierByOrderId(orderId);

  return result.data;
}

async function isMatchingCrefoNummer(
  token: string,
  state: RootState
): Promise<IsSameCrefoIdResponse> {
  const { orderId } = getContext(state);
  const headers = getDefaultHeaders(token);

  let result;
  try {
    result = await userManagementApi.ssoUserManagementControllerIsSameCrefoId(
      orderId,
      {
        headers,
      }
    );
  } catch (e) {
    console.error(e);
    throw e;
  }

  return result.data as IsSameCrefoIdResponse;
}

async function updateCrefoNummer(
  token: string,
  state: RootState
): Promise<string> {
  const { orderId } = getContext(state);
  const headers = getDefaultHeaders(token);

  let result;
  try {
    result =
      await userManagementApi.ssoUserManagementControllerUpdateUnverifiedCrefoId(
        orderId,
        {
          headers,
        }
      );
  } catch (e) {
    console.error(e);
    throw e;
  }

  return result.data as any;
}

export { getVerifierNameByOrderId, isMatchingCrefoNummer, updateCrefoNummer };
