import { useState } from 'react';

import { Typography, Paper, Hidden } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Popper from '@material-ui/core/Popper';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { crefoColors, defaultTheme } from '../../CrefoTheme';
import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { Popup } from '../Popups/Popup';

const iamSignatoryTextStyles = makeStyles({
  nextButton: {
    marginTop: '20px',
  },
  nextButtonLoading: {
    marginTop: '20px',
    backgroundColor: 'rgb(0, 158, 226) !important',
  },
  identityCard: {
    position: 'relative',
    width: '300px',
    height: '180px',
    marginTop: '30px',
  },
  margin: { marginTop: '30px' },
  searchMargin: { marginTop: '20px' },
  rightPadding: { paddingRight: 0 },
  signatoryText: {
    [defaultTheme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
});

const Content = () => {
  const { t } = useTranslation();
  return (
    <div>
      <Typography variant='h3'>
        {t('showSearch.secondaryHelp.text1')}
      </Typography>
      <div style={{ marginTop: '18px' }}>
        <div>
          <Typography>
            <span style={{ color: crefoColors.primary }}>{'- '}</span>
            {t('showSearch.secondaryHelp.text2')}
          </Typography>
        </div>
        <div style={{ marginTop: '13px' }}>
          <Typography>
            <span style={{ color: crefoColors.primary }}>{'- '}</span>
            {t('showSearch.secondaryHelp.text3')}
          </Typography>
        </div>
      </div>
      <div style={{ marginTop: '27px' }}>
        <Typography>{t('showSearch.secondaryHelp.text4')} </Typography>
        <a href={`mailto:${t('companySearch.noOptionsMail')}`}>
          {t('companySearch.noOptionsMail')}
        </a>
        .
      </div>
    </div>
  );
};

const SecondaryHelp = ({ open, anchorEl }) => {
  const classes = iamSignatoryTextStyles();
  return (
    <Popper
      placement='bottom'
      className={classes.secondaryHelpPopper}
      open={open}
      anchorEl={anchorEl}
      disablePortal={false}
    >
      <Paper elevation={3} style={{ width: '70%' }}>
        <Content />
      </Paper>
    </Popper>
  );
};

export const IamSignatoryText = ({ numberOfSignatories, disabled }) => {
  const classes = iamSignatoryTextStyles();
  const { t } = useTranslation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [toggle, setToggle] = useState(false);
  const verifierName = useAppSelector(selectVerifierName);

  const onClickHelpIcon = (event) => {
    if (!anchorEl) setAnchorEl(event.currentTarget);
    toggle ? setToggle(false) : setToggle(true);
  };

  const handleClickAway = () => {
    setToggle(false);
  };

  const signatorySubtitle = () => {
    if (disabled) {
      return (
        <span>
          {t('showSearch.signatoryPredefinedSubtitle1')}
          <i>{verifierName}</i>
          {t('showSearch.signatoryPredefinedSubtitle2')}
        </span>
      );
    }
    return t('showSearch.signatorySubtitle');
  };

  if (numberOfSignatories > 0) {
    return (
      <>
        <Typography
          variant='h2'
          component='h2'
          className={classes.signatoryText}
        >
          {t('showSearch.iamSignatory')}{' '}
          <ClickAwayListener onClickAway={handleClickAway}>
            <img
              style={{ marginBottom: '5px', cursor: 'pointer' }}
              onClick={onClickHelpIcon}
              src='icons/Kontexthilfe.svg'
              alt='context-help'
              id='helpIcon'
            />
          </ClickAwayListener>
          <Hidden smDown>
            <SecondaryHelp open={toggle} anchorEl={anchorEl} />
          </Hidden>
          <Hidden mdUp>
            {toggle ? (
              <Popup
                content={<Content />}
                onClose={() => {
                  setToggle(false);
                }}
              />
            ) : null}
          </Hidden>
        </Typography>
        <br />
        <br />
        <Typography
          variant='body1'
          align='left'
          className={classes.signatorySubtitle}
        >
          {signatorySubtitle()}
        </Typography>
      </>
    );
  }
  return <Typography variant='h2' component='h2' align='left' />;
};

IamSignatoryText.propTypes = {
  numberOfSignatories: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
};
