import { useAuth } from "react-oidc-context";

import { KeycloakPopUp } from '../Keycloak/KeycloakPopUp';

interface PrivateRouteProps {
  component: JSX.Element;
}

export const PrivateRoute = ({ component }: PrivateRouteProps) => {
  const auth = useAuth();

  if (auth.isAuthenticated) {
    return component;
  }

  return <KeycloakPopUp />;
};
