import { useEffect } from 'react';

import {current} from "@reduxjs/toolkit";
import { Provider } from 'react-redux';
import {persistStore} from "redux-persist";
import { PersistGate } from 'redux-persist/integration/react';

import {getRedirects} from "./ident/identSlice";
import {createReduxStore, RESET_APP} from './store';
import { initialSetupTargetPlatformOrder } from "./targetPlatform/targetPlatformSlice";
import {getQueryParam} from "../utils/helpers";

interface StoreProviderProps {
  children: React.ReactNode;
}

export const StoreProvider = ({ children }: StoreProviderProps) => {
  const store = createReduxStore();
  let persistor = persistStore(store)

  useEffect(() => {
    // store.dispatch({ type: RESET_APP });
    const existingOrderId = store.getState()?.targetPlatform?.order?.orderId;
    const currentOrderId = getQueryParam(window.location.search, 'prt');
    if (!!existingOrderId && !!currentOrderId && existingOrderId !== currentOrderId) {
      store.dispatch({ type: RESET_APP });
    }
    // get verifier name in order context
    store.dispatch<any>(initialSetupTargetPlatformOrder(''));
    // @ts-ignore
    store.dispatch(getRedirects(getQueryParam(window.location.search, 'prt') ?? ''))
  });

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};
