import { useState } from 'react';

import { useMediaQuery } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Hidden from '@material-ui/core/Hidden';
import { ThemeProvider } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { useTranslation } from 'react-i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';
import { useLocation } from 'react-router-dom';

import { CrefoStepper } from './CrefoStepper';
import { ReturnToTPPopUp } from './ReturnToTPPopUp';
import GuardianLogo from '../../assets/images/logo/Guardian.svg';
import crefoTheme, {
  headerGridContainerStyles,
  defaultTheme,
} from '../../CrefoTheme';
import { CrefoRoute } from '../../interfaces/crefo-route.interface';
import { useAppSelector } from '../../state/hooks';
import { selectState } from '../../state/ident/identSlice';
import { RESET_APP } from '../../state/store';
import { isCreditscoreRequired } from '../../utils/helpers';
import { Popup } from '../Popups/Popup';

interface HeaderProps {
  targetPlatform: string;
  activeStep: number;
  showTargetPlatformLink: boolean;
  showStepper: boolean;
}

export const Header = ({
  targetPlatform,
  activeStep,
  showTargetPlatformLink,
  showStepper,
}: HeaderProps) => {
  const classes = headerGridContainerStyles();
  const { t } = useTranslation();
  const location = useLocation();
  const auth = useAuth();
  const dispatch = useDispatch();
  const state = useAppSelector(selectState);

  let steps;
  if (isCreditscoreRequired()) {
    steps = [t('steps.1'), t('steps.2'), t('steps.3'), t('steps.4')];
  } else {
    steps = [t('steps.1'), t('steps.2'), t('steps.4')];
  }

  const showHeader = () =>
    [
      CrefoRoute.COMPANY_SEARCH.valueOf(),
      CrefoRoute.VERIFICATION.valueOf(),
      CrefoRoute.APPROVE_CREDENTIALS.valueOf(),
    ].includes(location.pathname);

  const myesgRoute = CrefoRoute.MY_ESG_VERIFICATION.valueOf().includes(
    location.pathname
  );

  const [popup, setPopup] = useState(false);
  const [helpPopup, setHelpPopup] = useState(false);
  const largeScreen = useMediaQuery(defaultTheme.breakpoints.up('md'));

  const logout = async () => {
    // @TODO: move this into an action
    dispatch({ type: RESET_APP });
    await auth.clearStaleState();
    auth.signoutSilent();
    //@ts-ignore
    window.location.href = state?.ident?.redirects?.loginUri;
    if (myesgRoute) {
      window.location.href = window._env_.PIN_LETTER_URL;
    }
  };

  const TargetPlatformLink = () => {
    if (showTargetPlatformLink) {
      return (
        <>
          <Hidden smDown>
            {myesgRoute ? (
              ''
            ) : (
              <>
                <Typography
                  style={{ marginRight: '20px' }}
                  className={classes.tpLink}
                  align='left'
                  onClick={() => setPopup(true)}>
                  <span className={classes.highlightedLinkRegular}>
                    {t('steps.gotoDestination') + targetPlatform}
                  </span>
                </Typography>
              </>
            )}
          </Hidden>
          <Hidden mdUp>
            {myesgRoute ? (
              ''
            ) : (
              <Typography
                style={{ marginRight: '20px' }}
                className={classes.tpLink}
                align='left'
                onClick={() => setPopup(true)}>
                <span className={classes.highlightedLinkRegular}>
                  {t('steps.gotoDestinationMobile') + targetPlatform}
                </span>
              </Typography>
            )}
          </Hidden>
        </>
      );
    }
    return <Typography align='left' />;
  };

  if (!showHeader) {
    return null;
  }
  return (
    <ThemeProvider theme={crefoTheme}>
      <div style={{ width: '100%' }}>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='center'
          spacing={largeScreen ? 3 : 0}>
          {myesgRoute ? (
            <>
              <Grid item xs={1} lg={1}>
                <div
                  style={{
                    marginTop: '20px',
                    marginLeft: '20px',
                    marginBottom: '10px',
                    display: 'flex',
                  }}>
                  <span>
                    <picture>
                      <img
                        style={{
                          minHeight: '60px',
                          height: '60px',
                          minWidth: '60px',
                          width: '60px',
                        }}
                        srcSet={GuardianLogo}
                        alt={t('steps.creditReformAlt')}
                      />
                    </picture>
                  </span>
                </div>
              </Grid>
            </>
          ) : (
            <Hidden mdDown>
              <Grid item lg={1}>
                <div
                  style={{ marginTop: '20px', marginLeft: '20px' }}
                  className={classes.crefoTrustLogo}>
                  <span className='logo'>
                    <picture>
                      <img
                        srcSet={GuardianLogo}
                        alt={t('steps.creditReformAlt')}
                      />
                    </picture>
                  </span>
                </div>
              </Grid>
            </Hidden>
          )}

          <Grid item xs={5} md={5} lg={3}>
            <TargetPlatformLink />
          </Grid>
          <Grid item xs={6} md={6} lg={8}>
            <Typography className={classes.helpTextTypo} align='right'>
              <span
                className={classes.highlightedLinkRegular}
                onClick={() => setHelpPopup(true)}>
                {t('steps.help')}
              </span>
              <span
                className={classes.highlightedLinkRegular}
                style={{ margin: '20px' }}
                onClick={() => logout()}>
                {t('steps.logout')}
              </span>
            </Typography>
            {helpPopup && (
              <Popup
                title={t('steps.helpPopupTitle')}
                content={
                  <>
                    <Typography style={{ paddingBottom: 5 }}>
                      {t('steps.helpPopupSubtitle')}
                    </Typography>
                    <a href={`mailto:${t('steps.helpPopupMailAddress')}`}>
                      {t('steps.helpPopupMailAddress')}
                    </a>
                  </>
                }
                fullScreen={false}
                customWidth='auto'
                onClose={() => setHelpPopup(false)}
              />
            )}
          </Grid>

          {myesgRoute ? (
            <></>
          ) : (
            <>
              <Hidden mdDown>
                <Grid
                  container
                  item
                  xs={12}
                  lg={12}
                  direction='column'
                  justifyContent='center'
                  alignItems='stretch'
                  spacing={2}
                  className={classes.stepperItem}>
                  <CrefoStepper
                    activeStep={activeStep}
                    steps={steps}
                    showStepper={showStepper}
                    desktop
                  />
                </Grid>
              </Hidden>
              <Hidden lgUp>
                <Grid item xs={12}>
                  <CrefoStepper
                    activeStep={activeStep}
                    steps={steps}
                    showStepper={showStepper}
                    desktop={false}
                  />
                </Grid>
              </Hidden>
            </>
          )}
        </Grid>
        {popup ? (
          <ReturnToTPPopUp
            targetplatformName={targetPlatform}
            onCloseCallBack={() => setPopup(false)}
          />
        ) : (
          <div />
        )}
      </div>
    </ThemeProvider>
  );
};
