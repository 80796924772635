export const IdentProvider = {
  ItmVideoIdent: 'itm_videoident',
  NectAutoIdent: 'nect_autoident',
  DID: 'did',
  CREFO_FIRMENWISSEN: 'crefo_firmenwissen',
  None: 'none',
};

export const getIdentProviderName = (provider) => {
  switch (provider) {
    case IdentProvider.ItmVideoIdent:
      return 'VideoIdent';
    case IdentProvider.NectAutoIdent:
      return 'AutoIdent';
    default:
      return 'eines anderen Verfahrens';
  }
};
