import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { Popup } from './Popup';

const Content = () => {
  const { t } = useTranslation();
  const verifierName = useAppSelector(selectVerifierName);
  return (
    <div style={{ marginLeft: '0px' }}>
      <div>
        {t('mobilePopOver.text1').replace('[targetPlatform]', verifierName)}
      </div>
      <div style={{ marginTop: '30px' }}>
        {t('mobilePopOver.text2').replace('[targetPlatform]', verifierName)}
      </div>
    </div>
  );
};

interface MobilePopOverProps {
  onClickCloseButton: () => void;
}

export const MobilePopOver = ({ onClickCloseButton }: MobilePopOverProps) => {
  const { t } = useTranslation();
  return (
    <Popup
      title={t('mobilePopOver.title')}
      content={<Content />}
      onClose={onClickCloseButton}
      fullScreen={false}
    />
  );
};
