export enum RequestResult {
  SUCCESS = 'SUCCESS',
  CUSTOMER_INPUT = 'CUSTOMER_INPUT',
  UNKNOWN_PROBLEM = 'UNKNOWN_PROBLEM',
  UNEXPECTED_CANCELLATION = 'UNEXPECTED_CANCELLATION',
  NAME_MISMATCH = 'NAME_MISMATCH',
  TECHNICAL_PROBLEM = 'TECHNICAL_PROBLEM',
  DOCUMENT_EXPIRED = 'DOCUMENT_EXPIRED',
  MISSING_AUTHORIZED_REPRESENTATIVE = 'MISSING_AUTHORIZED_REPRESENTATIVE',
  CREDENTIALS_INSUFFICIENT = 'CREDENTIALS_INSUFFICIENT',
  ORDER_PRESETS_MISMATCH = 'ORDER_PRESETS_MISMATCH',
  ITM_VIDEOIDENT = 'itm_videoident',
  NECT_AUTOIDENT = 'nect_autoident',
}
