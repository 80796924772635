import './App.css';

import { ThemeProvider } from "@material-ui/core";
import { WebStorageStateStore } from "oidc-client-ts";
import { useTranslation } from 'react-i18next';
import { AuthProvider } from "react-oidc-context";
import { Route, Routes, BrowserRouter } from 'react-router-dom';

import { ApproveCredentials } from './components/ApproveCredentials/ApproveCredentials';
import { CompanySearch } from './components/CompanySearch/CompanySearch';
import { CredentialsInsufficient } from './components/CredentialsInsufficient/CredentialsInsufficient';
import {CrefoIdGuard} from "./components/CrefoIdGuard/crefo-id-guard";
import { CustomerInputRequired } from './components/CustomerInputRequired/CustomerInputRequired';
import { Imprint } from './components/Imprint/Imprint';
import {CircularLoad} from "./components/Load/CircularLoad";
import { MyEsgVerification } from './components/PinIdentMyESG/MyESGVerification';
import { NoMatch } from './components/Routing/NoMatch';
import { PrivateRoute } from './components/Routing/PrivateRoute';
import { SelectIdentProvider } from './components/SelectIdentProvider/SelectIdentProvider';
import { StateRouter } from './components/StateRouter/StateRouter';
import { VerificationResult } from './components/VerificationResult/VerificationResult';
import crefoTheme from "./CrefoTheme";
import {useAppSelector} from "./state/hooks";
import { selectOidcConfig } from "./state/ident/identSlice";


export const App = () => {
    const userStore =  new WebStorageStateStore({
        store: localStorage
    })
    const onSigninCallback = () => {
        setTimeout(() => {
            // remove #state to allow silentSignin
            window.location.replace("#");
            window.history.replaceState({}, '', window.location.href.slice(0, -1));
        }, 500);
    }
    const oidcConfig = useAppSelector(selectOidcConfig)

  const { t } = useTranslation();

  return (
      (!!oidcConfig) ? <AuthProvider {...oidcConfig} onSigninCallback={onSigninCallback} userStore={userStore}>
          <BrowserRouter>
              <StateRouter>
                  <div className='App'>
                      <Routes>
                          <Route exact path='imprint' element={<Imprint />} />
                          <Route
                              exact
                              path='companysearch'
                              element={
                                  <PrivateRoute component={<CompanySearch />}></PrivateRoute>
                              }></Route>
                          <Route
                              exact
                              path='selectidentprovider'
                              element={
                                  <PrivateRoute
                                      component={<SelectIdentProvider />}></PrivateRoute>
                              }></Route>
                          <Route
                              exact
                              path='verification'
                              element={
                                  <PrivateRoute
                                      component={<VerificationResult />}></PrivateRoute>
                              }></Route>
                          <Route
                              exact
                              path='approvecredentials'
                              element={
                                  <PrivateRoute
                                      component={<ApproveCredentials />}></PrivateRoute>
                              }></Route>
                          <Route
                              exact
                              path='customerinputrequired'
                              element={
                                  <PrivateRoute component={<CustomerInputRequired />} />
                              }></Route>
                          <Route
                              exact
                              path='credentialsinsufficient'
                              element={
                                  <PrivateRoute component={<CredentialsInsufficient />} />
                              }></Route>
                          <Route
                              exact
                              path='/myesgverification'
                              element={
                                  <PrivateRoute component={
                                      <CrefoIdGuard><MyEsgVerification /></CrefoIdGuard>
                                  } />
                              }></Route>
                          <Route path='*' element={<NoMatch />}></Route>
                      </Routes>
                  </div>
              </StateRouter>
          </BrowserRouter>
      </AuthProvider> : (
          <div className='App'>
              <ThemeProvider theme={crefoTheme}>
                  <CircularLoad
                      loading={true}
                      customMessage={t('keycloakPopUp.loadingMessage')}
                  />
              </ThemeProvider>
          </div>
      )
  );
};
