import { Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from '../../state/hooks';
import { selectVerifierName } from '../../state/ident/identSlice';
import { NavigationStyles } from './Navigation.styles';

export const BackButton = (props: any) => {
  const { t } = useTranslation();
  const classes = NavigationStyles();
  const verifierName = useAppSelector(selectVerifierName);
  return (
    <Button
      className={classes.backButton}
      disabled={props.buttonDisabled}
      onClick={() => props.setPopup(true)}
      variant='outlined'
      color='secondary'
    >
      {t('approveCredentials.backButton')}
      <br />
      {verifierName}
    </Button>
  );
};
