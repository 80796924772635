import { useTranslation } from 'react-i18next';

export const ErrorView = ({ targetPlatform }) => {
  const { t } = useTranslation();
  return (
    <>
      <p>
        {t('approveCredentials.errorView').replace(
          '[targetPlatform]',
          targetPlatform
        )}
      </p>
    </>
  );
};
