import React, { Fragment } from 'react';

import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { htmlTranslator } from '../../utils/htmlTranslator';
import { Popup } from '../Popups/Popup';

const Content = () => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ maxWidth: '500px' }}>
        <Typography>{htmlTranslator(t('sendCredentials.text1'))}</Typography>
      </div>
    </>
  );
};

export const SendingCredentialsPopUp = ({ onClose }) => {
  const { t } = useTranslation();
  return (
    <Popup
      title={t('sendCredentials.title')}
      onClose={onClose}
      content={<Content />}
    />
  );
};
