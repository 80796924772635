import { makeStyles } from '@material-ui/core/styles';

import { crefoColors } from '../../CrefoTheme';

export const titleStyle = {
  display: 'inline',
  color: '#fff',
  background: '#009ee2',
  boxShadow: '10px 0 0 #009ee2, -10px 0 0 #009ee2',
  boxDecorationBreak: 'clone',
  '@media only screen and (min-width: 1140px)': {
    padding: 0,
  },
  '@media only screen and (max-width: 1140px)': {
    padding: 0,
  },
  '@media only screen and (max-width: 768px)': {
    padding: '10px 0px',
  },
  '@media only screen and (max-width: 320px)': {
    padding: '10px 0px',
  },
} as any;

export const boxContainer = {
  maxWidth: '1110px',
  width: '100%',
  padding: '30px 0px 30px 0px',
} as any;

export const gridContainer = {
  display: 'flex',
  backgroundColor: '#F6F5F2',
  alignItems: 'center',
  flexDirection: 'column',
} as any;

export const contentContainer = {
  display: 'flex',
  gap: '30px',
  justifyContent: 'flex-start',
  flexDirection: 'column',
  width: '480px',
} as any;

export const continuousText = {
  '@media only screen and (min-width: 1140px)': {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.5px',
  },
  '@media only screen and (max-width: 1140px)': {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.5px',
  },
  '@media only screen and (max-width: 768px)': {
    fontSize: '15px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '26px',
    letterSpacing: '0.5px',
  },
  '@media only screen and (max-width: 320px)': {
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '24px',
    letterSpacing: '0.5px',
  },
} as any;

export const button = {
  marginBottom: '0px',
  textTransform: 'none',
  fontWeight: 600,
  lineHeight: '26px',
  letterSpacing: '0.5px',
  fontStyle: 'normal',
  height: '56px',
  width: '100%',
  fontFamily: 'Open Sans',
  fontSize: '15px',
  fontStretch: 'normal',
  textAlign: 'center',
  borderRadius: '50px',
  color: '#ffffff',
  backgroundColor: crefoColors.primary,
  '&.Mui-disabled': {
    backgroundColor: crefoColors['c-grau'],
    color: 'white',
  },
  '&:hover': {
    backgroundColor: crefoColors['c-s-2'],
    color: 'white',
    borderColor: crefoColors['c-s-2'],
  },
  '@media only screen and (min-width: 1140px)': {
    fontSize: '15px',
  },
  '@media only screen and (max-width: 1140px)': {
    fontSize: '15px',
  },
  '@media only screen and (max-width: 768px)': {
    fontSize: '15px',
  },
  '@media only screen and (max-width: 320px)': {
    fontSize: '14px',
    lineHeight: '24px',
  },
} as any;

export const boxContainerPinVerification = {
  maxWidth: '1110px',
  width: '100%',
  padding: '0px',
} as any;

export const paddingPinVerificationGrid = {
  '@media only screen and (max-width: 1140px)': {
    padding: '0px 20px 30px 20px',
  },
  '@media only screen and (max-width: 768px)': {
    padding: '0px 20px 30px 20px',
  },
} as any;

export const paddingRightCard = {
  '@media only screen and (min-width: 1140px)': {
    padding: '0px 20px 0px 30px',
  },
  '@media only screen and (max-width: 1140px)': {
    padding: '0px 20px 30px 20px',
  },
  '@media only screen and (max-width: 768px)': {
    padding: '0px 20px 30px 20px',
  },
};

export const titlePadding = {
  '@media only screen and (min-width: 1140px)': {
    padding: 0,
  },
  '@media only screen and (max-width: 1140px)': {
    padding: '0px 20px 0px 20px',
  },
  '@media only screen and (max-width: 768px)': {
    padding: '0px 20px 0px 20px',
  },
};

export const stepperPadding = {
  '@media only screen and (min-width: 1140px)': {
    padding: 0,
  },
  '@media only screen and (max-width: 1140px)': {
    padding: '0px 20px 0px 20px',
  },
  '@media only screen and (max-width: 768px)': {
    padding: '0px 20px 0px 20px',
  },
} as any;

export const cardContentPadding = {
  padding: '30px',
} as any;

// TODO jss-to-tss-react codemod: usages of this hook outside of this file will not be converted.
export const ContentStyles = makeStyles({
  gridContainer,
  boxContainer,
  continuousText,
  titleStyle,
  boxContainerPinVerification,
  paddingPinVerificationGrid,
  paddingRightCard,
  titlePadding,
  stepperPadding,
  cardContentPadding,
  titlePosition: {
    marginLeft: '10px',
    padding: '60px 0px 00px 0px',
    width: '100%',
    '@media only screen and (max-width: 768px)': {
      padding: '30px 0px 0px 0px',
    },
  },
  cardSizeRightBox: {
    '@media only screen and (min-width: 1140px)': {
      padding: 0,
      height: '100%',
      minWidth: 280,
      width: '80.47337378106508%',
    },
    '@media only screen and (max-width: 1140px)': {
      padding: 0,
      height: '100%',
      minWidth: 280,
      width: '80.47337378106508%',
    },

    '@media only screen and (max-width: 768px)': {
      padding: 0,
      height: '100%',
      minWidth: 280,
      width: '100%',
    },
  },
  imageNotGeneric: {
    paddingBottom: '20px',
    '@media only screen and (min-width: 1140px)': {
      height: '125px',
      width: '247.34042px',
    },
    '@media only screen and (max-width: 1140px)': {
      height: '125px',
      width: '247.34042px',
    },
    '@media only screen and (max-width: 768px)': {
      height: '125px',
      width: '247.34042px',
    },
    '@media only screen and (max-width: 320px)': {
      height: '111px',
      width: '219px',
    },
  },
  imageGeneric: {
    paddingBottom: '20px',
    '@media only screen and (min-width: 1140px)': {
      height: '125px',
      width: '127.47pxx',
    },
    '@media only screen and (max-width: 1140px)': {
      height: '125px',
      width: '127.47px',
    },
    '@media only screen and (max-width: 768px)': {
      height: '125px',
      width: '127.47px',
    },
    '@media only screen and (max-width: 320px)': {
      height: '98px',
      width: '89.04002px',
    },
  },
});
