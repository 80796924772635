import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

interface PopupProps {
  title: string;
  content: JSX.Element;
  onClose: () => void;
  fullScreen: boolean;
  customWidth?: string;
}

export const Popup = ({
  title,
  content,
  onClose,
  fullScreen,
  customWidth,
}: PopupProps) => {
  const isCloseAble = onClose !== undefined;

  const CloseButton = () => {
    if (isCloseAble) {
      return (
        <CloseIcon
          aria-label='close'
          data-testid='popup-close'
          onClick={onClose}
          style={{
            cursor: 'pointer',
            position: 'absolute',
            right: '10',
            top: '10',
          }}
        />
      );
    }
    return <div />;
  };

  const alignment: React.CSSProperties = { left: '0px', position: 'absolute' };

  return (
    <Dialog
      fullScreen={fullScreen}
      aria-labelledby='dialog-title'
      open
      style={fullScreen && isCloseAble ? alignment : undefined}
      maxWidth='md'
    >
      <CloseButton />
      <DialogTitle disableTypography id='dialog-title'>
        <Typography variant='h3'>{title}</Typography>
      </DialogTitle>
      <DialogContent style={{ width: customWidth }}>{content}</DialogContent>
    </Dialog>
  );
};
