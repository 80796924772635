import {
  Grid,
  Typography,
  CircularProgress,
  makeStyles,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { defaultTheme } from '../../CrefoTheme';

const circularLoadStyles = makeStyles({
  header: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '90px',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '50px',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
  isLoadingText: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '30px',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '30px',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '20px',
    },
  },
  spinner: {
    [defaultTheme.breakpoints.up('lg')]: {
      marginTop: '200px',
    },
    [defaultTheme.breakpoints.only('md')]: {
      marginTop: '100px',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '30px',
    },
  },
});

export const CircularLoad = ({ loading, customMessage }) => {
  const classes = circularLoadStyles();
  const { t } = useTranslation();
  const message =
    customMessage || t('approveCredentials.fetchCredentialsLoading');
  if (loading) {
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Typography
            variant='h2'
            component='h2'
            className={classes.header}
            align='center'
          >
            {t('approveCredentials.fetchCredentialsWelcome')}{' '}
          </Typography>
          <Typography
            variant='body1'
            component='p'
            className={classes.isLoadingText}
            align='center'
          >
            {message}{' '}
          </Typography>
        </Grid>
        <Grid item xs={12} lg={12} md={12}>
          {loading && (
            <CircularProgress
              data-testid='circular-progress'
              className={classes.spinner}
              size={100}
              color='primary'
            />
          )}
        </Grid>
      </Grid>
    );
  }
  return <div />;
};
