import { useEffect, useState } from 'react';

import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  ThemeProvider,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { t } from 'i18next';
import { useAuth } from 'react-oidc-context';
import { useDispatch } from 'react-redux';

import { MismatchUnverifiedCrefonummerStyles } from './mismatch-unverified-crefonummer.styles';
import { IsSameCrefoIdResponse } from '../../api-clients/core-service-api-react';
import arrow from '../../assets/images/arrow.svg';
import genericLogoOnFailure from '../../assets/images/genericLogoOnFailure.svg';
import mockUpOption1 from '../../assets/images/mockUpOption1.svg';
import mockUpOption1Background from '../../assets/images/mockUpOption1Background.svg';
import mockUpOption2 from '../../assets/images/mockUpOption2.svg';
import mockUpOption2Background from '../../assets/images/mockUpOption2Background.svg';
import crefoTheme from '../../CrefoTheme';
import { useAppSelector } from '../../state/hooks';
import { selectState, sendCredentials } from '../../state/ident/identSlice';
import { RESET_APP } from '../../state/store';
import { updateCrefoNummer } from '../../state/targetPlatform/targetPlatformApi';
import { Header } from '../Header/Header';

export const MismatchUnverifiedCrefonummer = (props: IsSameCrefoIdResponse) => {
  const classes = MismatchUnverifiedCrefonummerStyles();
  const state = useAppSelector(selectState);
  const auth = useAuth();
  const dispatch = useDispatch();
  const isMobile = useMediaQuery(useTheme().breakpoints.down('md'));
  const [adjusted, adjustCrefoNummer] = useState<boolean>(false);
  const { verifiedFor, depositedOnAccount } = props;

  const logout = async () => {
    dispatch({ type: RESET_APP });
    await auth.clearStaleState();
    auth.signoutSilent();
    window.location.href = window._env_.PIN_LETTER_URL;
  };

  useEffect(() => {
    if (state.targetPlatform.order?.orderId && adjusted) {
      (async () => {
        try {
          await updateCrefoNummer(auth.user?.access_token as string, state);
          dispatch(sendCredentials());
        } catch (e) {
          console.error(e);
        }
      })();
    }
  }, [adjusted]);

  return (
    <ThemeProvider theme={crefoTheme}>
      <Header
        targetPlatform={'Bautler'}
        showTargetPlatformLink
        activeStep={0}
        showStepper={false}
      />
      <Box className={classes.firstBoxContainer}>
        <Grid container className={classes.firstGridContainer}>
          <Grid item xs={12} className={classes.titlePadding}>
            {isMobile ? (
              <Typography className={classes.titlePosition}>
                <span className={classes.titleStyle}>
                  {t('mismatchUnverifiedCrefonummer.titleMobile')}
                </span>
              </Typography>
            ) : (
              <Typography className={classes.titlePosition}>
                <span className={classes.titleStyle}>
                  {t('mismatchUnverifiedCrefonummer.title')}
                </span>
              </Typography>
            )}
          </Grid>

          <Grid container className={classes.gridContainerLeft2}>
            <Grid
              item
              xs={12}
              lg={6}
              className={classes.paddingPinVerificationGrid}>
              <Box sx={{ minWidth: 280, height: '100%' }}>
                <Card style={{ padding: 30 }}>
                  <CardContent style={{ padding: 0 }}>
                    <Grid container spacing={1} style={{ padding: 0 }}>
                      <Grid
                        item
                        xs={12}
                        style={{ paddingBottom: 0 }}
                        className={classes.gridContinuousText}>
                        <Typography className={classes.continuousText}>
                          {t('mismatchUnverifiedCrefonummer.section')}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ padding: '10px 7.5px 0px 7.5px' }}
                        className={classes.gridTypographyCompany}>
                        <Typography className={classes.typographyCompany}>
                          {t('mismatchUnverifiedCrefonummer.verifiedFor')}
                          &nbsp;{verifiedFor?.legalName}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        style={{ padding: '0px 7.5px 7.5px 7.5px' }}
                        className={classes.gridTypographyCompany}>
                        <Typography className={classes.typographyCompany}>
                          {t(
                            'mismatchUnverifiedCrefonummer.depositedOnAccount'
                          )}
                          &nbsp; {depositedOnAccount?.legalName}
                        </Typography>
                      </Grid>
                    </Grid>
                  </CardContent>
                </Card>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={6}
              className={classes.paddingRightCard}>
              <Box sx={{ height: '100%', minWidth: 280 }}>
                <Card className={classes.cardSizeRightBox}>
                  <CardContent className={classes.cardContentPadding}>
                    <Box
                      display='flex'
                      alignItems='center'
                      justifyContent='center'>
                      <img
                        src={genericLogoOnFailure}
                        alt={'genericLogo'}
                        className={classes.genericLogoOnFailure}
                      />
                    </Box>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.secondBoxContainer}>
        <Grid container className={classes.boxContainerGrid}>
          <Grid container>
            <Grid item xs={12} className={classes.titlePadding}>
              <Typography className={classes.titlePosition2}>
                <span className={classes.titleStyle}>
                  {t('mismatchUnverifiedCrefonummer.title2')}
                </span>
              </Typography>
            </Grid>

            {isMobile ? (
              <>
                <span className={classes.span}></span>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={8}
                  className={classes.gridContainerLeft}>
                  <Grid container className={classes.gridContainerLeft2}>
                    <Grid item xs={12}>
                      <Typography className={classes.typographyOptionTitle}>
                        <span className={classes.titleOption}>
                          {t('mismatchUnverifiedCrefonummer.titleOption1')}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item xs={12}>
                      <Typography className={classes.typographyOptionSubtitle}>
                        <span className={classes.subtitleOption}>
                          {t('mismatchUnverifiedCrefonummer.subtitleOption1')}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid item xs={12} style={{ padding: 0 }}>
                      <Typography className={classes.typographyOptionSection}>
                        <span className={classes.sectionOption}>
                          {t('mismatchUnverifiedCrefonummer.sectionOption1', {
                            verifiedFor: `${verifiedFor?.legalName}`,
                          })}
                        </span>
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      style={{ padding: 0 }}
                      className={classes.gridButtonOption1}
                      onClick={() => adjustCrefoNummer(true)}>
                      <Button
                        variant='contained'
                        className={classes.buttonOption}>
                        <span className={classes.buttonOption1Span}>
                          {t('mismatchUnverifiedCrefonummer.buttonOption1')}
                        </span>
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>

                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  className={classes.gridContainerOption}>
                  <Box className={classes.boxImageOption1}>
                    <img
                      src={mockUpOption1Background}
                      alt={'mobileMockUp'}
                      className={classes.mockUpOption1Background}
                    />
                    <img
                      src={mockUpOption1}
                      alt={'mobileMockUp'}
                      className={classes.mockUpOption1}
                    />
                  </Box>
                </Grid>
              </>
            ) : (
              <>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={4}
                  style={{ padding: 0 }}
                  className={classes.gridContainerOption}>
                  <Box className={classes.boxImageOption1}>
                    <img
                      src={mockUpOption1Background}
                      alt={'mobileMockUp'}
                      className={classes.mockUpOption1Background}
                    />
                    <img
                      src={mockUpOption1}
                      alt={'mobileMockUp'}
                      className={classes.mockUpOption1}
                    />
                  </Box>
                </Grid>
                <Grid
                  item
                  xs={12}
                  md={12}
                  lg={8}
                  style={{ padding: 0 }}
                  className={classes.gridContainerOption}>
                  <Grid container className={classes.gridContainerLeft2}>
                    <Grid item xs={12}>
                      <Typography className={classes.typographyOptionTitle}>
                        <span className={classes.titleOption}>
                          {t('mismatchUnverifiedCrefonummer.titleOption1')}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                      <Typography className={classes.typographyOptionSubtitle}>
                        <span className={classes.subtitleOption}>
                          {t('mismatchUnverifiedCrefonummer.subtitleOption1')}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid item xs={12} style={{ padding: 0 }}>
                      <Typography className={classes.typographyOptionSection}>
                        <span className={classes.sectionOption}>
                          {`${t(
                            'mismatchUnverifiedCrefonummer.sectionOption1',
                            {
                              verifiedFor: `${verifiedFor?.legalName}`,
                            }
                          )}`}
                        </span>
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      style={{ padding: 0 }}
                      className={classes.gridButtonOption1}
                      onClick={() => adjustCrefoNummer(true)}>
                      <Button
                        variant='contained'
                        className={classes.buttonOption}>
                        <span className={classes.buttonOption1Span}>
                          {t('mismatchUnverifiedCrefonummer.buttonOption1')}
                        </span>
                        <img src={arrow} alt='arrow' />
                      </Button>
                    </Grid>
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.secondBoxContainer}>
        <Grid container className={classes.secondGridContainer}>
          {isMobile ? <span className={classes.span}></span> : ''}
          <Grid container className={classes.gridContainerLeft}>
            <Grid item xs={12} md={12} lg={8}>
              <Grid container className={classes.gridContainerLeft2}>
                <Grid item xs={12}>
                  <Typography className={classes.typographyOptionTitle}>
                    <span className={classes.titleOption}>
                      {t('mismatchUnverifiedCrefonummer.titleOption2')}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                  <Typography className={classes.typographyOptionSubtitle}>
                    <span className={classes.subtitleOption}>
                      {t('mismatchUnverifiedCrefonummer.subtitleOption2')}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} style={{ padding: 0 }}>
                  <Typography className={classes.typographyOptionSection}>
                    <span className={classes.section1Option}>
                      {t('mismatchUnverifiedCrefonummer.section1Option2')}
                    </span>
                  </Typography>
                  <Typography className={classes.typographyOptionSection1}>
                    <span className={classes.sectionOption}>
                      {t('mismatchUnverifiedCrefonummer.section2Option2')}
                    </span>
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.gridButtonOption1}>
                  <Button
                    variant='contained'
                    className={classes.buttonOption2}
                    onClick={() => logout()}>
                    {isMobile ? (
                      <span className={classes.buttonOption1Span}>
                        {t('mismatchUnverifiedCrefonummer.buttonOption2Mobile')}
                      </span>
                    ) : (
                      <>
                        <span className={classes.buttonOption1Span}>
                          {t('mismatchUnverifiedCrefonummer.buttonOption2')}
                        </span>
                        <img src={arrow} alt='arrow' />
                      </>
                    )}
                  </Button>
                </Grid>
              </Grid>
            </Grid>

            <Grid
              item
              xs={12}
              md={12}
              lg={3}
              className={classes.gridContainerOption2}>
              <Box className={classes.boxImageOption2}>
                <img
                  src={mockUpOption2Background}
                  alt={'mobileMockUp'}
                  className={classes.mockUpOption2Background}
                />
                <img
                  src={mockUpOption2}
                  alt={'mobileMockUp'}
                  className={classes.mockUpOption2}
                />
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Box>

      <Box className={classes.gridContainer4} />
    </ThemeProvider>
  );
};
