import { Hidden } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { v4 as uuidv4 } from 'uuid';

import WarningIcon from '../../assets/images/icons/Warning.svg';
import { Dropdown } from '../Dropdown/Dropdown';
import { SignatoryCard } from './SignatoryCard';

const displaySignatoriesStyles = makeStyles({
  emptySignatoryItem: { height: '100%', width: 160 },
  signatoryRow: { paddingBottom: 20 },
  signatoryGroup: { marginTop: '20px' },
});

export const DisplaySignatories = (props) => {
  const classes = displaySignatoriesStyles();
  const { t } = useTranslation();

  const {
    signatories,
    chosenSignatory,
    updateChosenSignatory,
    noSignatoryFound,
    disabled,
  } = props;
  // Not queried yet --> show placeholder
  if (
    (!Array.isArray(signatories) || signatories.length === 0) &&
    !noSignatoryFound
  ) {
    return (
      <Hidden smDown>
        <Typography>{t('showSearch.signatoriesPlaceholder')}</Typography>
      </Hidden>
    );
    // No signatory found for company --> show link to crefo mail
  }
  if (noSignatoryFound) {
    return (
      <Grid container>
        <Grid item lg={12}>
          <Grid item lg={1}>
            <img srcSet={WarningIcon} alt='warning-icon' />
          </Grid>
          <Grid item lg={11}>
            <Typography align='left'>
              <span>{t('showSearch.noSignatoriesForCompany')}</span>
              <br />
              <span>
                {t('showSearch.pleaseMessage')}
                <a href='mailto:support@crefotrust.de'>support@crefotrust.de</a>
                .
              </span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    );
    // Less than 9 signatories found --> show them as cards in max 2 rows
  }
  if (signatories.length < 9) {
    const signatoryJsx = [];
    // for loop separates indices of first row (0-3) and second row (4-7)
    for (let signatoryIndex = 0; signatoryIndex < 8; signatoryIndex += 4) {
      // subarray with items of current row
      const curSignatories = signatories.slice(
        signatoryIndex,
        signatoryIndex + 4
      );
      if (curSignatories.length === 0) break;
      const signatoryCards = [];
      // for loop runs through 4 positions of current row
      for (let indexInRow = 0; indexInRow < 4; indexInRow++) {
        // if signatory for that position exists --> insert
        if (indexInRow < curSignatories.length) {
          const signatory = curSignatories[indexInRow];
          signatoryCards.push(
            <Grid item xs='auto' key={signatory.id}>
              <SignatoryCard
                {...signatory}
                chosenSignatory={chosenSignatory}
                updateChosenSignatory={updateChosenSignatory}
                disabled={disabled}
              />
            </Grid>
          );
          // if no signatory exists --> add empty div so grid is aligned properly
        } else {
          signatoryCards.push(
            <Grid item xs='auto' key={uuidv4()}>
              <div className={classes.emptySignatoryItem} />
            </Grid>
          );
        }
      }
      // push signary row
      signatoryJsx.push(
        <Grid
          key={signatoryIndex}
          container
          item
          className={classes.signatoryRow}
          xs={12}
          justifyContent='flex-start'
        >
          {signatoryCards}
        </Grid>
      );
    }
    return (
      <Grid
        container
        item
        lg={12}
        className={classes.signatoryGroup}
        justifyContent='flex-start'
      >
        <Hidden mdUp>
          <Dropdown
            {...{ chosenSignatory, signatories, updateChosenSignatory }}
          />
        </Hidden>
        <Hidden smDown>{signatoryJsx}</Hidden>
      </Grid>
    );
    // 9 or more signatories found --> show them in dropdown list
  }
  return (
    <Dropdown {...{ chosenSignatory, signatories, updateChosenSignatory }} />
  );
};

DisplaySignatories.propTypes = {
  signatories: PropTypes.arrayOf(PropTypes.shape({})),
  chosenSignatory: PropTypes.shape({}),
  updateChosenSignatory: PropTypes.func.isRequired,
  noSignatoryFound: PropTypes.bool.isRequired,
  disabled: PropTypes.bool.isRequired,
};
