import { makeStyles } from '@material-ui/core/styles';

import { crefoColors } from '../../CrefoTheme';

export const identProviderCardStyles = (props) =>
  makeStyles({
    container: {
      borderRadius: '15px',
    },
    highlightContainer: {
      height: `${props.highlightOffset.toString()}px`,
    },
    highlightText: {
      lineHeight: `${props.highlightOffset.toString()}px`,
      color: '#FFFFFF',
    },
    paper: {
      borderRadius: '15px',
    },
    buttonContainer: {
      margin: '10px 0px',
    },
    contentContainer: {
      padding: 0,
    },
    imageContainer: {
      height: '100px',
      margin: '5px 0px',
    },
    outlinedSecondary: {
      backgroundColor: 'white',
      color: crefoColors['c-p-1'],
      borderColor: crefoColors['c-p-1'],
      border: '1px solid !important',
      width: '100%',
      '&.rounded': {
        borderRadius: '50px !important',
      },
      '&:hover': {
        backgroundColor: crefoColors.primary,
        color: 'white',
      },
      '&.clicked': {
        backgroundColor: crefoColors.primary,
        color: 'white',
      },
    },
  });
