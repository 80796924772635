import {
  configureStore,
  ThunkAction,
  Action,
  Dispatch,
  AnyAction,
  ThunkDispatch,
  EnhancedStore,
  combineReducers,
} from '@reduxjs/toolkit';
import { AuthState } from "react-oidc-context";
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import { identReducer } from './ident/identSlice';
import { inputReducer } from './input/inputSlice';
import { targetPlatformReducer } from "./targetPlatform/targetPlatformSlice";
import {
  IdentState,
  InputState, TargetPlatformState,
} from '../interfaces/redux-states.interface';

const allReducers = combineReducers({
  ident: identReducer,
  input: inputReducer,
  targetPlatform: targetPlatformReducer,
});

export const RESET_APP = 'RESET_APP';

const rootReducer = (state: any | undefined, action: AnyAction) => {
  if (action.type === RESET_APP) {
    return allReducers(undefined, action);
  }

  return allReducers(state, action);
};

export const createReduxStore = () =>
  configureStore({
    reducer: persistReducer(persistConfig, rootReducer),
    middleware: (getDefaultMiddleware: Function) =>
      getDefaultMiddleware({
        thunk: {},
        serializableCheck: false,
      }),
  });

const persistConfig = {
  key: 'root',
  storage,
};

export const persistor = (store: EnhancedStore<RootState, AnyAction, any>) =>
  persistStore(store);

export type RootState = {
  auth: AuthState;
  ident: IdentState;
  input: InputState;
  targetPlatform: TargetPlatformState;
};

export type AppDispatch = ThunkDispatch<RootState, any, AnyAction> &
  Dispatch<AnyAction>;

export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;

export type CrefoThunkConfig = {
  dispatch: AppDispatch;
  state: RootState;
  rejectValue: string;
};
