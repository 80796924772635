import { Button, SvgIcon } from '@material-ui/core';
import LoadingButton from '@material-ui/lab/LoadingButton';

import { NavigationStyles } from './Navigation.styles';
import { RepeatVerificationButton } from './RepeatVerificationButton';
import { ReactComponent as ArrowNext } from '../../assets/images/icons/arrow_next.svg';

export const ContinueButton = ({
  requestResult,
  loading,
  disabled,
  onClick,
  text,
  buttonWidth,
}: any) => {
  const classes = NavigationStyles();

  switch (requestResult) {
    case 'UNKNOWN_PROBLEM':
    case 'UNEXPECTED_CANCELLATION':
    case 'NAME_MISMATCH':
    case 'DOCUMENT_EXPIRED':
    case 'TECHNICAL_PROBLEM':
      return <RepeatVerificationButton />;
    case 'MISSING_AUTHORIZED_REPRESENTATIVE':
    case 'ORDER_PRESETS_MISMATCH':
      return <></>;
    // TODO: add other ident api
    case 'itm_videoident':
      return <></>;
    default:
      return (
        <>
          {loading ? (
            <LoadingButton
              data-testid='continueBtn'
              className={classes.nextButtonLoading}
              pending
              style={buttonWidth ? { width: buttonWidth } : { width: '100%' }}
            />
          ) : (
            <Button
              data-testid='continueBtn'
              disabled={disabled}
              onClick={onClick}
              style={buttonWidth ? { width: buttonWidth } : { width: '100%' }}
              endIcon={
                <SvgIcon component={ArrowNext} style={{ marginTop: 10 }} />
              }
              className={classes.nextButton}
            >
              {text}
            </Button>
          )}
        </>
      );
  }
};
