import { makeStyles } from '@material-ui/core/styles';

import { defaultTheme, crefoColors } from '../../CrefoTheme';

export const NavigationStyles = makeStyles({
  nextButtonContainer: {
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'right',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  navigationWrapper: {
    zIndex: 1,
    [defaultTheme.breakpoints.up('md')]: {
      height: 120,
      position: 'fixed',
      maxWidth: '100vw',
      width: '100vw',
      bottom: 0,
      left: 0,
      backgroundColor: 'white',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      left: '0px !important',
      width: '100vw !important',
      height: 100,
    },
  },
  navigationContainer: {
    left: 0,
    width: '100vw',
    [defaultTheme.breakpoints.up('md')]: {
      maxHeight: 150,
      webkitBoxShadow: '0px -9px 10px 0px rgba(255,255,255,1)',
      mozBoxShadow: '0px -9px 10px 0px rgba(255,255,255,1)',
      boxShadow: '0px -9px 10px 0px rgba(255,255,255,1)',
      backgroundColor: '#ffffff',
    },
  },
  smallButton: {
    marginTop: 20,
    width: '100%',
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  backButton: {
    width: '100%',
    [defaultTheme.breakpoints.up('md')]: {
      maxWidth: 285,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: 12,
    },
  },
  nextButton: {
    marginTop: '20px',
    maxWidth: '100%',
    [defaultTheme.breakpoints.up('md')]: {
      maxWidth: 285,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      fontSize: 12,
      width: '100%',
    },
  },
  nextButtonLoading: {
    [defaultTheme.breakpoints.up('md')]: {
      maxWidth: 285,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      width: '100%',
    },
    marginTop: '20px',
    backgroundColor: 'rgb(0, 158, 226) !important',
    maxWidth: '100%',
  },
  toTargetPlatformLink: {
    color: crefoColors['c-p-1'],
  },
});
