import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Popup } from '../Popups/Popup';

interface ContentProps {
  targetPlatform: string;
}

const Content = ({ targetPlatform }: ContentProps) => {
  const { t } = useTranslation();

  return (
    <>
      <div style={{ maxWidth: '500px' }}>
        <Typography>{t('noRedirect.text1')}</Typography>
        <a href='/companysearch'>
          {'>'} {t('noRedirect.text2')}
        </a>
      </div>
      <Typography style={{ marginTop: '40px', maxWidth: '500px' }}>
        {t('noRedirect.text3')}
      </Typography>
      <div style={{ marginTop: '20px', maxWidth: '500px' }}>
        <Typography>{t('noRedirect.text4')}</Typography>
        <a href='mailto:support@crefotrust.de'>
          {'>'} {t('noRedirect.text5')}
        </a>
      </div>
      <div style={{ marginTop: '20px', maxWidth: '500px' }}>
        <Typography>
          {t('noRedirect.text6').replace('[targetPlatform]', targetPlatform)}
        </Typography>
        <a
          href='#'
          // onClick={() => dispatch(gotoTargetPlatform(verificationResult))}
        >
          {'>'}{' '}
          {t('noRedirect.text7').replace('[targetPlatform]', targetPlatform)}
        </a>
      </div>
    </>
  );
};

interface NoRedirectProps {
  onClose: () => void;
  targetPlatform: string;
}

export const NoRedirect = ({ onClose, targetPlatform }: NoRedirectProps) => {
  const { t } = useTranslation();
  return (
    <Popup
      title={t('noRedirect.title')}
      onClose={onClose}
      content={<Content targetPlatform={targetPlatform} />}
      fullScreen={false}
      customWidth={'auto'}
    />
  );
};
