import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

interface OverlayProps {
  isLoading: boolean;
}

export const Overlay = ({ isLoading }: OverlayProps) => {
  const classes = useStyles();

  return (
    <div>
      <Backdrop className={classes.backdrop} open={isLoading}>
        <CircularProgress data-testid='overlay-loader' size={50} />
      </Backdrop>
    </div>
  );
};
