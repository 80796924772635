import { createTheme, makeStyles } from '@material-ui/core/styles';

// const averta = {
//   fontFamily: 'Averta',
//   fontStyle: 'normal',
//   fontWeight: 800,
//   src: `url(${AvertaPeLight}) format('woff2')`
// };

// const avertaBold = {
//   fontFamily: 'Averta Bold',
//   fontStyle: 'bold',
//   fontWeight: 600,
//   src: `url(${AvertaPeBold}) format('woff2')`
// };

export const crefoColors = {
  'c-grau-hell': '#edece5',
  h2: '#005790',
  'c-p-1': '#009ee2', // gleiches wie primary
  'c-grau': '#b5b5b5',
  'c-grau-2': '#7f7f7f',
  'c-grau-3': '#4C4C4C',
  primary: 'rgb(0, 158, 226)',
  'c-s-2': '#006eb7',
  'c-s-3': '#004884',
  'c-error': '#e85c73',
  'c-error-2': '#e05469',
  'c-success': '#9ccc40',
  'c-warning': '#f8b820',
};

const crefoBreakpoints = {
  xs: 0,
  sm: 320,
  md: 768,
  lg: 1140,
};

// used to access mui breakpoints in crefoTheme
export const defaultTheme = createTheme({
  breakpoints: {
    values: crefoBreakpoints,
  },
});

const crefoTheme = createTheme({
  spacing: 15,
  typography: {
    fontFamily: [
      'Open Sans',
      'Arial',
      'Helvetica',
      'sans-serif', // Für Fließtexte kommt ausschließlich die Schriftart Open Sans Regular zum Einsatz.
    ].join(','),
    fontSize: 15,
    fontWeight: 'normal',
    body1: {
      fontFamily: 'Open Sans',
      fontSize: '15px',
      fontWeight: 'normal',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 1.73,
      letterSpacing: '0.2px',
      color: '#616161',
    },
    h1: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '2.533rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      color: 'rgb(0, 158, 226)',
    },
    h2: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '1.733rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      color: crefoColors.h2,
    },
    h3: {
      fontFamily: ['Averta Std', 'Open Sans', 'Arial'],
      fontSize: '1.333rem',
      fontWeight: 600,
      letterSpacing: '0.5px',
      color: 'rgb(0, 72, 132)',
    },
  },
  breakpoints: {
    values: crefoBreakpoints,
  },
  palette: {
    text: {
      primary: crefoColors['c-grau-3'], // Die Standard-Schriftfarbe für Fließtexte ist C-Grau 3 (RGB: 76, 76, 76).
    },
    primary: {
      main: crefoColors.primary,
    },
    secondary: {
      main: '#f44336', // rot. zum test
    },
    background: {
      // default: 'rgb(237, 236, 229)' //crefo grau
      default: 'rgb(255, 255, 255)', // weiss
    },
    error: {
      main: crefoColors['c-error'],
    },
  },
  overrides: {
    MuiButton: {
      // override the styles of all instances of this component
      root: {
        // Name of the rule
        [defaultTheme.breakpoints.up('md')]: {
          width: '263px',
        },
        height: '56px',
        fontFamily: 'Open Sans',
        fontSize: '15px',
        fontWeight: 'normal',
        fontStretch: 'normal',
        fontStyle: 'normal',
        lineHeight: 1.73,
        letterSpacing: '0.2px',
        textAlign: 'center',
        borderRadius: 0,
        color: '#ffffff',
        backgroundColor: crefoColors.primary,
        '&.Mui-disabled': {
          backgroundColor: crefoColors['c-grau'],
          color: 'white',
        },
        '&:hover': {
          backgroundColor: crefoColors['c-s-2'],
          color: 'white',
        },
      },
      outlinedSecondary: {
        backgroundColor: 'white',
        color: crefoColors['c-p-1'],
        borderColor: crefoColors['c-p-1'],
        border: '1px solid !important',
        '&:hover': {
          backgroundColor: crefoColors.primary,
          color: 'white',
        },
      },
    },
    BulkDeleteButton: {
      color: 'rgb(0, 158, 226)',
    },
    MuiStepper: {
      horizontal: {
        flexDirection: 'row',
        alignItems: 'center',
        paddingLeft: 0,
        paddingRight: 0,
      },
    },
    MuiMobileStepper: {
      root: {
        paddingTop: 0,
      },
    },
    MuiStepConnector: {
      line: {
        borderColor: crefoColors['c-grau-hell'],
      },
    },
    MuiStepIcon: {
      root: {
        color: crefoColors['c-grau'],
        '&$active': {
          color: crefoColors['c-s-2'],
        },
        '&$completed': {
          color: crefoColors['c-s-2'],
        },
        width: '30px',
        height: '30px',
      },
    },
    MuiPaper: {
      root: {
        padding: 16,
        fontFamily: 'Open Sans',
        letterSpacing: '0.2px',
      },
    },
    Paper: {
      // padding: spacing(2),
      // textAlign: 'justified',
      color: crefoColors['c-grau'],
    },
    MuiAutocomplete: {
      clearIndicator: {
        visibility: 'visible',
      },
      inputRoot: {
        paddingRight: '15px!important',
      },
      paper: {
        padding: '0px!important',
        borderRadius: 0,
      },
      listbox: {
        padding: '0px!important',
        maxHeight: '50vh!important',
      },
      option: {
        padding: '0px!important',
      },
    },
    MuiFormLabel: {
      root: {
        '&.Mui-error': {
          color: crefoColors['c-grau-3'],
        },
      },
    },
    MuiFormControlLabel: {
      label: {
        [defaultTheme.breakpoints.down('sm')]: {
          paddingTop: 10,
        },
      },
    },
    MuiOutlinedInput: {
      root: {
        borderRadius: 0,
        fontFamily: 'Open Sans',
        letterSpacing: '0.2px',
      },
    },
    MuiCheckbox: {
      root: {
        color: 'transparent',
        width: 28,
        height: 28,
        border: `solid 1px ${crefoColors['c-grau']}`,
        borderRadius: 0,
        marginRight: 10,
      },
    },
    MuiAvatar: {
      img: {
        objectFit: 'contain',
        width: '77%',
      },
    },
    MuiDivider: {
      root: {
        height: 1,
        margin: 0, // Reset browser default style.
        border: 'none',
        flexShrink: 0,
        backgroundColor: crefoColors['c-grau'],
      },
      light: {
        height: 31,
        backgroundColor: 'rgba(255,255,255,0)',
        backgroundImage:
          'linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255))',
      },
    },
  },
  customStyles: {
    companySearchFieldStyles: {
      highlightedFontWeight: { fontWeight: 700 },
      regularFontWeight: { fontWeight: 400 },
      clearIcon: { width: 23, paddingRight: 3 },
      companyAddress: {
        color: crefoColors['c-grau'],
      },
      option: {
        borderBottom: `1px solid ${crefoColors['c-grau-hell']}`,
        paddingTop: '10px',
        paddingBottom: '10px',
        marginLeft: '16px',
        marginRight: '16px',
      },
      notYourCompany: {
        cursor: 'default',
        paddingTop: '10px',
        paddingBottom: '10px',
        width: '100%',
        paddingLeft: '16px',
        paddingRight: '16px',
      },
    },
    identityCard: {
      idDivider: {
        width: 2,
        height: 33,
        backgroundColor: crefoColors['c-grau'],
      },
      belowIdCard: {
        [defaultTheme.breakpoints.down('sm')]: {
          maxWidth: 300,
        },
        [defaultTheme.breakpoints.only('md')]: {
          marginTop: 30,
          paddingLeft: '0px!important',
          paddingBottom: '0px!important',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          maxWidth: 300,
        },
      },
      idCardPaper: {
        width: '298px',
        height: 188,
        marginTop: 30,
        borderRadius: 15,
      },
      cardItem: {
        marginTop: 25,
        textAlign: 'left',
      },
      divider: { width: 180 },
      defaultText: {
        color: crefoColors['c-grau'],
      },
      selectedText: {
        fontStyle: 'italic',
        color: '#ffffff',
      },
      credentialDetails: {
        fontSize: 9,
        fontFamily: 'Open Sans',
        color: crefoColors['c-grau'],
      },
      idCardTitle: {
        color: '#ffffff',
        fontSize: 12,
        fontFamily: ['Menlo', 'Open Sans', 'Arial'],
      },
      menloText: {
        fontFamily: ['Menlo', 'Open Sans', 'Arial'],
        fontSize: '9px',
        color: crefoColors['c-grau'],
      },
    },
    verifyPopup: {
      grid: {
        [defaultTheme.breakpoints.only('sm')]: {
          height: '100%',
          width: '100%',
        },
      },
      modal: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: 'auto',
        },
        [defaultTheme.breakpoints.only('md')]: {
          marginTop: '114px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          display: 'flex',
        },
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: '-webkit-center',
      },
      modalContainer: {
        backgroundColor: 'white',
        textAlign: 'left',
        height: '100%',
        maxWidth: '600px',
      },
    },
    signatoryCard: {
      card: {
        width: 160,
        height: 160,
        borderRadius: 0,
        padding: 0,
        margin: 10,
      },
      bgImage: {
        width: 160,
        height: 160,
        padding: 0,
        textTransform: 'none',
      },
      credentials: {
        width: 160,
        height: 155,
        paddingLeft: 5,
        paddingBottom: 5,
        textAlign: 'left',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-end',
      },
      selectedTop: {
        marginLeft: 0,
        width: 160,
        height: 5,
        backgroundColor: '#004884',
      },
      regularTop: {
        marginLeft: 0,
        width: 160,
        height: 5,
        backgroundColor: 'white',
      },
    },
    signatoryDropdown: {
      dropdown: {
        border: `1px solid ${crefoColors['c-grau']}`,
        height: 58,
        marginTop: 30,
      },
      hoveredDropdown: {
        border: `1px solid ${crefoColors.primary}`,
        height: 58,
        marginTop: 30,
      },
      activeDropdown: {
        border: `1px solid ${crefoColors.primary}`,
        marginTop: 30,
      },
      openDropdown: {
        border: `1px solid ${crefoColors['c-grau']}`,
        marginTop: 30,
      },
      selection: {
        height: 58,
        textAlign: 'left',
        paddingTop: 0,
      },
      option: {
        height: 58,
        textAlign: 'left',
        borderTop: `1px solid ${crefoColors['c-grau']}`,
        padding: 0,
        cursor: 'pointer',
      },
      hoveredOptionStyle: {
        height: 58,
        textAlign: 'left',
        borderTop: `1px solid ${crefoColors['c-grau']}`,
        padding: 0,
        backgroundColor: crefoColors.primary,
        color: 'white',
        cursor: 'pointer',
      },
      listItem: {
        textTransform: 'none',
        paddingLeft: 14,
        paddingRight: 10,
        color: 'inherit',
      },
      collapse: {
        width: '100%',
      },
      activeIcon: {
        color: crefoColors.primary,
      },
    },
    returnPopup: {
      buttonContainer: {
        marginTop: '30px',
      },
      button1: {
        [defaultTheme.breakpoints.down('sm')]: {
          width: '100%',
        },
      },
      button2: {
        [defaultTheme.breakpoints.down('sm')]: {
          marginTop: '20px',
          width: '100%',
        },
        [defaultTheme.breakpoints.up('md')]: {
          float: 'right',
        },
      },
    },
    headerGridContainerStyles: {
      crefoTrustLogo: { textAlign: 'left' },
      stepperItem: {
        paddingLeft: 0,
        paddingRight: 0,
        [defaultTheme.breakpoints.down('sm')]: {
          paddingTop: '0px !important',
        },
      },
      verificationText: { display: 'inline', lineHeight: 1.73 },
      helpTextTypo: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: 10,
        },
        [defaultTheme.breakpoints.up('lg')]: {
          marginTop: '20px',
          marginRight: '30px',
        },
      },
      tpLink: {
        [defaultTheme.breakpoints.down('sm')]: {
          margin: 10,
        },
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: 20,
        },
      },
      verificationNumber: {
        fontWeight: 'bold',
        display: 'inline',
        fontSize: '26px',
        color: crefoColors['c-p-1'],
        margin: '5px',
      },
      highlightedTextRegular: {
        fontStyle: 'regular',
        color: crefoColors.primary,
      },
      highlightedLinkRegular: {
        cursor: 'pointer',
        fontStyle: 'regular',
        color: crefoColors.primary,
      },
    },
    waitForVerificationStyle: {
      container: {
        height: '100px',
      },
      headline: {
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: 30,
        },
        [defaultTheme.breakpoints.down('sm')]: {
          textAlign: 'left',
        },
      },
      text: {
        marginTop: '10px',
      },
      spinner: {
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: '250px',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          marginTop: '20px',
          marginLeft: '33%',
        },
      },
      secondaryInfo: {
        paddingTop: 10,
      },
      myEsgSpinner: {
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: '250px',
        },
        marginBottom: '20px',
        color: 'rgb(0, 158,226)',
      },
    },
    videoVerificationStyles: {
      mdPaddingNoTopNoBottom: {
        [defaultTheme.breakpoints.only('md')]: {
          paddingTop: '0px!important',
          paddingBottom: '0px!important',
        },
      },
      identityCardContainer: {
        [defaultTheme.breakpoints.only('md')]: {
          paddingBottom: '0px!important',
        },
      },
      typographyMargin: {
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: '40px',
        },
      },
      typographyHeader: {
        [defaultTheme.breakpoints.up('md')]: {
          marginTop: '40px',
          textAlign: 'left',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          textAlign: 'left',
        },
      },
      listItem: { paddingBottom: 0, paddingTop: 0 },
      avatar: {
        width: '80px',
        height: '75px',
        paddingRight: '5px',
      },
      avatarSmall: {
        width: '32px',
        height: '20px',
        paddingRight: '5px',
      },
      instructions: {
        [defaultTheme.breakpoints.up('md')]: { width: '150px' },
        textAlign: 'left',
        paddingLeft: 8,
      },
      formControlLabel: {
        textAlign: 'left',
      },
      enumNumber: {
        color: '#00ade7',
        marginRight: '10px',
      },
      toVerificationButton: {
        [defaultTheme.breakpoints.down('sm')]: {
          width: '100%',
          position: 'sticky',
        },
      },
      formPadding: {
        paddingLeft: '15px',
        [defaultTheme.breakpoints.only('md')]: {
          paddingTop: 15,
        },
      },
      modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: '-webkit-center',
      },
      modalButton: { width: '770px', marginTop: '10px' },
    },
    footerGridContainer: {
      sticky: {
        [defaultTheme.breakpoints.up('md')]: {
          position: 'fixed',
          bottom: 0,
          left: 0,
          top: 'auto',
          width: '100%',
          padding: 0,
          margin: 0,
        },
      },
      largeButton: {
        [defaultTheme.breakpoints.down('md')]: {
          justifyContent: 'flex-end',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          justifyContent: 'center',
        },
      },
    },
    noCredentialsPopUp: {
      modal: {
        [defaultTheme.breakpoints.down('sm')]: {
          marginTop: '34px',
          margin: 'auto',
        },
        [defaultTheme.breakpoints.only('md')]: {
          marginTop: '114px',
        },
        [defaultTheme.breakpoints.up('lg')]: {
          display: 'flex',
        },
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: '-webkit-center',
      },
      modalContainer: {
        backgroundColor: 'white',
        textAlign: 'left',
        maxWidth: '600px',
      },
    },
    imprintStyles: {
      outerGridContainer: {
        textAlign: 'left',
        [defaultTheme.breakpoints.up('lg')]: {
          marginTop: '90px',
        },
        [defaultTheme.breakpoints.only('md')]: {
          marginTop: '50px',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          marginTop: '30px',
        },
      },
      title: {
        [defaultTheme.breakpoints.up('md')]: {
          textAlign: 'left',
        },
        [defaultTheme.breakpoints.down('sm')]: {
          textAlign: 'center',
        },
      },
      addressText: {
        marginTop: '30px',
        textAlign: 'left',
      },
      legalNormsText: {
        marginTop: '20px',
        textAlign: 'left',
      },
      signatoriesText: {
        marginTop: '20px',
        textAlign: 'left',
      },
      signatoriesTextList: {
        listStyleType: 'none',
        marginLeft: '50px',
        textAlign: 'left',
      },
      highlightedTextRegular: {
        fontStyle: 'regular',
        color: crefoColors.primary,
      },
    },
  },
});

export const companySearchFieldStyles = makeStyles(
  crefoTheme.customStyles.companySearchFieldStyles
);
export const signatoryCardStyles = makeStyles(
  crefoTheme.customStyles.signatoryCard
);
export const signatoryDropdownStyles = makeStyles(
  crefoTheme.customStyles.signatoryDropdown
);

export const identityCardStyles = makeStyles(
  crefoTheme.customStyles.identityCard
);
export const headerGridContainerStyles = makeStyles(
  crefoTheme.customStyles.headerGridContainerStyles
);
export const videoVerificationStyles = makeStyles(
  crefoTheme.customStyles.videoVerificationStyles
);
export const footerGridContainerStyles = makeStyles(
  crefoTheme.customStyles.footerGridContainer
);
export const verifyPopupStyles = makeStyles(
  crefoTheme.customStyles.verifyPopup
);
export const waitForVerificationStyles = makeStyles(
  crefoTheme.customStyles.waitForVerificationStyle
);
export const noCredentialsPopUpStyles = makeStyles(
  crefoTheme.customStyles.noCredentialsPopUp
);
export const returnPopupStyles = makeStyles(
  crefoTheme.customStyles.returnPopup
);
export const imprintStyles = makeStyles(crefoTheme.customStyles.imprintStyles);

export default crefoTheme;
