import 'react-app-polyfill/ie9';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import { Suspense } from 'react';

import ReactDOM from 'react-dom';

import './index.css';
import { App } from './App';


// import i18n (needs to be bundled ;))
import { initI18n } from './i18n';
import { StoreProvider } from './state/store.provider';

initI18n();

ReactDOM.render(
    <Suspense fallback=''>
        <StoreProvider>
            <App />
        </StoreProvider>
    </Suspense>,
    document.getElementById('root')
);
