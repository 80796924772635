import PropTypes from 'prop-types';

import { getIdentProviderName } from './IdentProvider';

export const InsufficientMethods = ({ insufficientIdMethods }) => {
  const mappedMethods = insufficientIdMethods?.map((method) =>
    getIdentProviderName(method)
  );
  return (
    <b>
      {mappedMethods &&
        (mappedMethods?.length > 1
          ? mappedMethods
              .join()
              .replaceAll(',', ', ')
              .replace(/,(?=[^,]*$)/, ' und')
          : mappedMethods[0])}
    </b>
  );
};

InsufficientMethods.defaultProps = {
  insufficientIdMethods: undefined,
};

InsufficientMethods.propTypes = {
  insufficientIdMethods: PropTypes.arrayOf(PropTypes.string),
};
