import { makeStyles } from '@material-ui/core/styles';

import {
  continuousText,
  paddingPinVerificationGrid,
  paddingRightCard,
  titlePadding,
  titleStyle,
} from './content.styles';
import { crefoColors } from '../../CrefoTheme';

export const MismatchUnverifiedCrefonummerStyles = makeStyles({
  titleStyle,
  titlePadding,
  paddingPinVerificationGrid,
  continuousText,
  paddingRightCard,
  firstBoxContainer: {
    '@media only screen and (min-width: 1140px)': {
      display: 'flex',
      backgroundColor: '#F6F5F2',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 74px - 50px - 397px - 300.5px)',
    },
    '@media only screen and (max-width: 1140px)': {
      display: 'flex',
      backgroundColor: '#F6F5F2',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 74px - 50px - 677px - 330.5px)',
    },
    '@media only screen and (max-width: 768px)': {
      display: 'flex',
      backgroundColor: '#F6F5F2',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 74px - 50px - 737px)',
    },
  },
  firstGridContainer: {
    textAlign: 'start',
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '60px 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '60px 0px 0px 0px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '60px 0px 0px 0px',
    },
  },
  titlePosition: {
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    fontFamily: 'Averta Std',
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      fontSize: '48px',
      lineHeight: 'normal',
      paddingBottom: '0px',
    },
    '@media only screen and (max-width: 1140px)': {
      fontSize: '48px',
      lineHeight: 'normal',
      paddingBottom: '0px',
    },
    '@media only screen and (max-width: 768px)': {
      fontSize: '27px',
      lineHeight: '230%',
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '27px',
      lineHeight: '230%',
      paddingBottom: '30px',
    },
  },
  titlePosition2: {
    marginLeft: '10px',
    fontWeight: 700,
    fontStyle: 'normal',
    fontFamily: 'Averta Std',
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      fontSize: '48px',
      lineHeight: 'normal',
      paddingBottom: '60px',
    },
    '@media only screen and (max-width: 1140px)': {
      fontSize: '48px',
      lineHeight: 'normal',
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 768px)': {
      fontSize: '27px',
      lineHeight: '230%',
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '27px',
      lineHeight: '230%',
      paddingBottom: '30px',
    },
  },
  gridContainerLeft2: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 0px 0px 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 0px 0px 0px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
    '@media only screen and (max-width: 320px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
  },
  secondBoxContainer: {
    '@media only screen and (min-width: 1140px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 74px - 50px - 677px)',
    },
    '@media only screen and (max-width: 1140px)': {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      minHeight: 'calc(100vh - 74px - 50px - 527px)',
    },
  },
  boxContainerGrid: {
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '60px 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '60px 0px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 0px 0px 0px',
    },
  },
  secondGridContainer: {
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
    '@media only screen and (max-width: 320px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px',
    },
  },
  flexStart: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'start',
  },
  gridContainerLeft: {
    maxWidth: '1110px',
    width: '100%',
    padding: '60px 0px 0px 0px',
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 0px 0px 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 20px 0px 20px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 20px 0px 20px',
    },
  },
  gridContainerOption: {
    maxWidth: '1110px',
    width: '100%',
    display: 'flex',
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 0px 0px 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 20px 0px 20px',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 20px 30px 20px',
      justifyContent: 'center',
    },
  },
  gridContainerOption2: {
    maxWidth: '1110px',
    width: '100%',
    display: 'flex',
    '@media only screen and (min-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '30px 0px 60px 125px',
    },
    '@media only screen and (max-width: 1140px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 0px 30px 140px',
      justifyContent: 'left',
    },
    '@media only screen and (max-width: 768px)': {
      maxWidth: '1110px',
      width: '100%',
      padding: '0px 20px 30px 20px',
      justifyContent: 'center',
    },
  },
  gridContainer4: {
    maxWidth: '100%px',
    width: '100%',
    paddingTop: '60px',
    backgroundColor: '#F6F5F2',
    '@media only screen and (min-width: 1140px)': {
      paddingTop: '60px',
      minHeight: '60px',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingTop: '60px',
      minHeight: '60px',
    },
    '@media only screen and (max-width: 768px)': {
      paddingTop: '30px',
      minHeight: '30px',
    },
  },
  cardContentPadding: {
    '@media only screen and (min-width: 1140px)': {
      padding: '37.5px 30px 37.5px 30px !important',
    },
    '@media only screen and (max-width: 1140px)': {
      padding: '24px 30px',
    },
    '@media only screen and (max-width: 768px)': {
      padding: '24px 30px',
    },
  },
  cardSizeRightBox: {
    '@media only screen and (min-width: 1140px)': {
      padding: 0,
      height: '100%',
      minWidth: 220,
      width: '68.91089108910891%',
    },
    '@media only screen and (max-width: 1140px)': {
      padding: 0,
      height: '100%',
      minWidth: 220,
      width: '100%',
    },

    '@media only screen and (max-width: 768px)': {
      padding: 0,
      height: '100%',
      minWidth: 220,
      width: '100%',
    },
  },
  typographyCompany: {
    color: '#4C4C4C',
    fontSize: '15px',
    fontStyle: 'normal',
    lineHeight: '26px',
    letterSpacing: '0.075px',
    fontWeight: 700,
    fontFamily: 'Averta Std',
  },
  titleOption: {
    '@media only screen and (min-width: 1140px)': {
      color: '#009EE2',
      fontFamily: 'Averta Std',
      fontSize: '38px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.19px',
    },
    '@media only screen and (max-width: 1140px)': {
      color: '#009EE2',
      fontFamily: 'Averta Std',
      fontSize: '38px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.19px',
    },

    '@media only screen and (max-width: 768px)': {
      color: '#009EE2',
      fontFamily: 'Averta Std',
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.12px',
    },
  },
  subtitleOption: {
    color: '#4C4C4C',
    fontFamily: 'Averta Std',
    fontSize: '26px',
    fontStyle: 'normal',
    fontWeight: 700,
    lineHeight: '26px',
    letterSpacing: '0.13px',

    '@media only screen and (min-width: 1140px)': {
      color: '#4C4C4C',
      fontFamily: 'Averta Std',
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.13px',
    },
    '@media only screen and (max-width: 1140px)': {
      color: '#4C4C4C',
      fontFamily: 'Averta Std',
      fontSize: '26px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.13px',
    },

    '@media only screen and (max-width: 768px)': {
      color: '#4C4C4C',
      fontFamily: 'Averta Std',
      fontSize: '19px',
      fontStyle: 'normal',
      fontWeight: 700,
      lineHeight: '26px',
      letterSpacing: '0.095px',
    },
  },
  sectionOption: {
    letterSpacing: '0.075px',
  },
  section1Option: {
    letterSpacing: '0.075px',
    paddingBottom: '15px',
  },
  buttonOption: {
    textTransform: 'none',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.5px',
    fontStyle: 'normal',
    marginBottom: '0px',
    height: '56px',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontStretch: 'normal',
    textAlign: 'center',
    borderRadius: '50px !important',
    color: '#ffffff',
    backgroundColor: crefoColors.primary,
    '&.Mui-disabled': {
      backgroundColor: crefoColors['c-grau'],
      color: 'white',
    },
    '&:hover': {
      backgroundColor: crefoColors['c-s-2'],
      color: 'white',
      borderColor: crefoColors['c-s-2'],
    },
    '@media only screen and (min-width: 1140px)': {
      fontSize: '15px',
      width: '39.141%',
    },
    '@media only screen and (max-width: 1140px)': {
      fontSize: '15px',
      width: '39.141%',
    },
    '@media only screen and (max-width: 768px)': {
      fontSize: '15px',
      width: '100%',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '14px',
      lineHeight: '24px',
      width: '100%',
    },
  },
  buttonOption2: {
    textTransform: 'none',
    fontWeight: 600,
    lineHeight: '26px',
    letterSpacing: '0.5px',
    fontStyle: 'normal',

    marginBottom: '0px',
    height: '56px',
    fontFamily: 'Open Sans',
    fontSize: '15px',
    fontStretch: 'normal',
    textAlign: 'center',
    borderRadius: '50px !important',
    color: '#ffffff',
    backgroundColor: crefoColors.primary,
    '&.Mui-disabled': {
      backgroundColor: crefoColors['c-grau'],
      color: 'white',
    },
    '&:hover': {
      backgroundColor: crefoColors['c-s-2'],
      color: 'white',
      borderColor: crefoColors['c-s-2'],
    },
    '@media only screen and (min-width: 1140px)': {
      fontSize: '15px',
      width: '46.081%',
    },
    '@media only screen and (max-width: 1140px)': {
      fontSize: '15px',
      width: '46.081%',
    },
    '@media only screen and (max-width: 768px)': {
      fontSize: '15px',
      width: '100%',
    },
    '@media only screen and (max-width: 320px)': {
      fontSize: '14px',
      lineHeight: '24px',
      width: '100%',
    },
  },
  gridContinuousText: {
    padding: 0,
  },
  gridTypographyCompany: {
    paddingTop: '10px',
  },
  genericLogoOnFailure: {
    '@media only screen and (min-width: 1140px)': {
      height: '125px',
      width: '127.47136px',
    },
    '@media only screen and (max-width: 1140px)': {
      height: '125px',
      width: '127.47136px',
    },
    '@media only screen and (max-width: 768px)': {
      height: '98px',
      width: '99.93755px',
    },
    '@media only screen and (max-width: 320px)': {
      height: '98px',
      width: '99.93755px',
    },
  },
  span: {
    height: '15px',
    width: '100%',
    backgroundColor: '#F6F5F2',
  },
  typographyOptionTitle: {
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 768px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 320px)': {
      paddingBottom: '7.5px',
    },
  },
  typographyOptionSubtitle: {
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 768px)': {
      paddingBottom: '30px',
    },
    '@media only screen and (max-width: 320px)': {
      paddingBottom: '15px',
    },
  },
  typographyOptionSection: {
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 768px)': {
      paddingBottom: '15px',
    },
    '@media only screen and (max-width: 320px)': {
      paddingBottom: '10px',
    },
  },
  typographyOptionSection1: {
    textAlign: 'left',
    '@media only screen and (min-width: 1140px)': {
      paddingBottom: '20px',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingBottom: '20px',
    },
    '@media only screen and (max-width: 768px)': {
      paddingBottom: '20px',
    },
    '@media only screen and (max-width: 320px)': {
      paddingBottom: '10px',
    },
  },
  gridButtonOption1: {
    display: 'flex',
    '@media only screen and (min-width: 1140px)': {
      padding: '0px 0px 30px 0px !important',
    },
    '@media only screen and (max-width: 1140px)': {
      padding: '0px 0px 30px 0px !important',
    },
    '@media only screen and (max-width: 768px)': {
      padding: '0px 0px 30px 0px !important',
    },
    '@media only screen and (max-width: 320px)': {
      padding: '0px 0px 30px 0px !important',
    },
  },
  buttonOption1Span: {
    paddingRight: '15px',
  },
  boxImageOption1: {
    display: 'flex',
    padding: '0px',
    minHeight: '259px',
  },
  mockUpOption1Background: {
    position: 'relative',
    alignItems: 'center',
    height: '273px',
    '@media only screen and (min-width: 1140px)': {
      width: '305.544px',
    },
    '@media only screen and (max-width: 1140px)': {
      width: '305.544px',
    },
    '@media only screen and (max-width: 768px)': {
      width: '279.103px',
    },
    '@media only screen and (max-width: 320px)': {
      width: '279.103px',
    },
  },
  mockUpOption1: {
    position: 'absolute',
    paddingTop: '11px',
    height: '263.18px',
    marginLeft: '14.59px',
    '@media only screen and (min-width: 1140px)': {
      width: '273.614px',
    },
    '@media only screen and (max-width: 1140px)': {
      width: '273.614px',
    },
    '@media only screen and (max-width: 768px)': {
      width: '249.936px',
    },
    '@media only screen and (max-width: 320px)': {
      width: '249.936px',
    },
  },
  boxImageOption2: {
    display: 'flex',
    minHeight: '342px',

    '@media only screen and (min-width: 1140px)': {
      paddingLeft: '45px',
      justifyContent: 'center',
      alignContent: 'center',
    },
    '@media only screen and (max-width: 1140px)': {
      paddingLeft: '0px',
      justifyContent: 'center',
      alignContent: 'center',
    },
    '@media only screen and (max-width: 768px)': {
      padding: '0px',
      justifyContent: 'center',
      alignContent: 'center',
    },
    '@media only screen and (max-width: 320px)': {
      padding: '0px',
      justifyContent: 'center',
      alignContent: 'center',
    },
  },
  mockUpOption2Background: {
    position: 'absolute',
    alignItems: 'center',
    height: '342px',
    '@media only screen and (min-width: 1140px)': {
      width: '279.161px',
    },
    '@media only screen and (max-width: 1140px)': {
      width: '279.161px',
    },
    '@media only screen and (max-width: 768px)': {
      width: '279.161px',
    },
    '@media only screen and (max-width: 320px)': {
      width: '279.977px',
    },
  },
  mockUpOption2: {
    position: 'absolute',
    alignItems: 'center',
    height: '333.03px',
    '@media only screen and (min-width: 1140px)': {
      width: '249.98781px',
      padding: '8.97px 0px 0p 0px',
    },
    '@media only screen and (max-width: 1140px)': {
      width: '249.98781px',
      padding: '8.97px 0px 0px 0px',
    },
    '@media only screen and (max-width: 768px)': {
      width: '249.98781px',
      padding: '8.97px 0px 0px 0px',
    },
    '@media only screen and (max-width: 320px)': {
      width: '250.719px',
      padding: '8.97px 0px 0px 0px',
    },
  },
});
