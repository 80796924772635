import { Grid, MobileStepper } from '@material-ui/core';

import { headerGridContainerStyles } from '../../CrefoTheme';
import { DesktopStepper } from './DesktopStepper';

export const CrefoStepper = ({ showStepper, activeStep, steps, desktop }) => {
  const classes = headerGridContainerStyles();

  if (showStepper) {
    return (
      <Grid
        id='stepper'
        container
        item
        xs={12}
        direction='column'
        justifyContent='center'
        alignItems='stretch'
        spacing={desktop ? 2 : 0}
        className={classes.stepperItem}
      >
        {' '}
        {/* direction='column' for stretching component horizontally */}
        {desktop ? (
          <DesktopStepper active={activeStep} steps={steps} />
        ) : (
          <MobileStepper
            steps={steps}
            position='static'
            variant='progress'
            activeStep={activeStep}
          />
        )}
      </Grid>
    );
  }
  return <div />;
};
