import React, { useEffect } from 'react';

import { Hidden } from '@material-ui/core';

// dynamically updates height so footer does not overlap content
export const DynamicHeightContainer = (props) => {
  const [height, setHeight] = React.useState(null);
  useEffect(() => {
    const heightInterval = setInterval(() => {
      const contentSpace = document.documentElement.clientHeight - 180;
      const viewBodyDOM = document.getElementById('view-body');
      const contentHeight = viewBodyDOM ? viewBodyDOM.clientHeight : 0;
      let viewHeight = contentSpace;
      if (contentSpace < contentHeight) {
        viewHeight = contentHeight + 180;
      }
      if (viewHeight !== height) setHeight(viewHeight);
    }, 500);
    return () => clearInterval(heightInterval);
  });

  return (
    <>
      <Hidden mdUp initialWidth='sm'>
        <div style={{ height: '100vh' }}>
          <div id='view-body'>{props.children}</div>
        </div>
      </Hidden>
      <Hidden smDown>
        <div style={{ height }}>
          <div id='view-body'>{props.children}</div>
        </div>
      </Hidden>
    </>
  );
};
