import { Grid, Typography, Hidden, makeStyles } from '@material-ui/core';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckIcon from '@material-ui/icons/Check';
// TODO: remove this library and use the translation in the jsx part
import parse from 'html-react-parser';
import { useTranslation } from 'react-i18next';

import { crefoColors, defaultTheme } from '../../CrefoTheme';
import { RequestResult } from '../../interfaces/request-result.interface';
import { useAppSelector } from '../../state/hooks';
import { selectFlavor, selectVerifierName } from '../../state/ident/identSlice';
import { selectChosenCompany } from '../../state/input/inputSlice';
import { isCreditscoreRequired } from '../../utils/helpers';

const verificationInformationStyles = makeStyles({
  linkIcon: { height: 13, paddingRight: 10, paddingLeft: 10 },
  specialConcern: {
    [defaultTheme.breakpoints.up('md')]: {
      marginTop: 80,
    },
  },
  mailToSupportArrow: {
    fontSize: 16,
    marginTop: 4,
    marginRight: 6,
    color: crefoColors.primary,
  },
  infoTextMargin: {
    [defaultTheme.breakpoints.up('md')]: {
      marginTop: '30px',
      marginBottom: '60px',
    },
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '30px',
      marginBottom: '10px',
    },
  },
  verificationSuccessful: {
    [defaultTheme.breakpoints.up('md')]: {
      paddingTop: 30,
      height: 100,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      paddingBottom: 30,
    },
  },
  verificationSuccessfulIcon: {
    fontSize: 35,
    marginLeft: 10,
    color: crefoColors['c-s-3'],
  },
  infoTitle: {
    [defaultTheme.breakpoints.up('md')]: {
      paddingTop: 30,
    },
  },
  infoSubtitle: {
    [defaultTheme.breakpoints.up('md')]: {
      paddingTop: 30,
    },
    [defaultTheme.breakpoints.down('sm')]: {
      paddingTop: 20,
    },
  },
});

const MailToSupport = () => {
  const { t } = useTranslation();
  const classes = verificationInformationStyles();
  return (
    <Grid container alignItems='center'>
      <Grid item>
        <ArrowForwardIosIcon className={classes.mailToSupportArrow} />
      </Grid>
      <Grid item>
        {parse(
          t('showVerificationResult.missingAuthorizedRepresentative.mailTo')
        )}
      </Grid>
    </Grid>
  );
};

const Success = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();
  const chosenCompany = useAppSelector(selectChosenCompany);

  const verifierName = useAppSelector(selectVerifierName);
  const flavor = useAppSelector(selectFlavor);

  return (
    <div>
      <Grid container>
        <Grid container className={classes.verificationSuccessful}>
          <Grid item>
            <Typography variant='h2' component='h2' align='left'>
              {t('showVerificationResult.verificationSuccessfully')}
            </Typography>
          </Grid>
          <Hidden smDown>
            <Grid item>
              <CheckIcon className={classes.verificationSuccessfulIcon} />
            </Grid>
          </Hidden>
        </Grid>
        <Grid item style={{ paddingLeft: 0 }}>
          <Typography variant='body1' align='left'>
            {flavor === 'B2B'
              ? t('showVerificationResult.infoText')
              : t('showVerificationResult.infoTextB2C')}
          </Typography>
          <br />
          {isCreditscoreRequired() && (
            <Typography variant='body1' align='left'>
              {t('showVerificationResult.nextStepCwc')
                .replace('[COMPANY]', chosenCompany?.legalName ?? '')
                .replace('[targetPlatform]', verifierName)}
            </Typography>
          )}
        </Grid>
      </Grid>
    </div>
  );
};

const MissingAuthorizedRepresentative = () => {
  const classes = verificationInformationStyles();
  const infoClasses = verificationInformationStyles();
  const { t } = useTranslation();
  const chosenCompany = useAppSelector(selectChosenCompany);

  return (
    <Grid>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t(
            'showVerificationResult.missingAuthorizedRepresentative.partiallyWorked'
          )}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoTextMargin}>
        <Typography variant='body1' align='left'>
          {t(
            'showVerificationResult.missingAuthorizedRepresentative.identityWorked'
          )}
        </Typography>
        <br />
        <Typography variant='body1' align='left'>
          {t(
            'showVerificationResult.missingAuthorizedRepresentative.signatoryFailed'
          ).replace('[COMPANY]', chosenCompany?.legalName ?? '')}
        </Typography>
        <br />
        <Typography variant='body1' align='left'>
          {parse(
            t(
              'showVerificationResult.missingAuthorizedRepresentative.supportWillContact'
            )
          )}
        </Typography>
        <br />
        <Typography
          variant='body1'
          align='left'
          className={infoClasses.specialConcern}
        >
          {t(
            'showVerificationResult.missingAuthorizedRepresentative.specialConcern'
          )}
        </Typography>
        <br />
        <MailToSupport />
        <br />
      </Grid>
    </Grid>
  );
};

const OrderPresetsMismatch = () => {
  const classes = verificationInformationStyles();
  const infoClasses = verificationInformationStyles();
  const { t } = useTranslation();
  const verifierName = useAppSelector(selectVerifierName);

  return (
    <Grid>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.orderPresetsMismatch.partiallyWorked')}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoTextMargin}>
        <Typography variant='body1' align='left'>
          {t('showVerificationResult.orderPresetsMismatch.identityWorked')}
        </Typography>
        <br />
        <Typography variant='body1' align='left'>
          {t('showVerificationResult.orderPresetsMismatch.mismatch').replaceAll(
            '[targetPlatform]',
            verifierName
          )}
        </Typography>
        <br />
        <Typography
          variant='body1'
          align='left'
          className={infoClasses.specialConcern}
        >
          {t('showVerificationResult.orderPresetsMismatch.specialConcern')}
        </Typography>
        <br />
        <MailToSupport />
        <br />
      </Grid>
    </Grid>
  );
};

const UnknownProblem = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();

  return (
    <Grid alignItems='flex-start'>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.unknownError.text1')}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.unknownError.text2')}
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          - {t('showVerificationResult.unknownError.text3')} <br />-{' '}
          {t('showVerificationResult.unknownError.text4')} <br />-{' '}
          {t('showVerificationResult.unknownError.text5')} <br />
        </Typography>
      </Grid>
      <Grid item lg={6}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {parse(t('showVerificationResult.unknownError.text6'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const UnexpectedCancellation = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();

  return (
    <Grid alignItems='flex-start'>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.unexpectedCancellation.text1')}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.unexpectedCancellation.text2')}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {parse(t('showVerificationResult.unexpectedCancellation.text3'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const NameMismatch = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();

  return (
    <Grid alignItems='flex-start'>
      <Grid item xs={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.nameMismatch.text1')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.nameMismatch.text2')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {parse(t('showVerificationResult.nameMismatch.text3'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const DocumentExpired = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();

  return (
    <Grid alignItems='flex-start'>
      <Grid item xs={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.documentExpired.text1')}
        </Typography>
      </Grid>
      <Grid item xs={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.documentExpired.text2')}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {t('showVerificationResult.documentExpired.text3')}
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <ul>
          <li>
            <Typography
              variant='body1'
              align='left'
              style={{ marginLeft: '20px' }}
              className={classes.infoTextMargin}
            >
              {t('showVerificationResult.documentExpired.text4')}
            </Typography>
          </li>
          <li>
            <Typography
              variant='body1'
              align='left'
              style={{ marginLeft: '20px' }}
              className={classes.infoTextMargin}
            >
              {t('showVerificationResult.documentExpired.text5')}
            </Typography>
          </li>
        </ul>
      </Grid>
    </Grid>
  );
};

interface Props {
  requestResult?: string;
}

const UndefinedState = ({
  requestResult = RequestResult.UNKNOWN_PROBLEM,
}: Props) => {
  const { t } = useTranslation();
  const classes = verificationInformationStyles();
  return (
    <Grid alignItems='flex-start'>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.undefinedProblem.text1')}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.undefinedProblem.text2') + requestResult}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {parse(t('showVerificationResult.undefinedProblem.text3'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

const TechnicalProblem = () => {
  const classes = verificationInformationStyles();
  const { t } = useTranslation();

  return (
    <Grid alignItems='flex-start'>
      <Grid item lg={12} className={classes.infoTitle}>
        <Typography variant='h2' component='h2' align='left'>
          {t('showVerificationResult.technicalProblem.text1')}
        </Typography>
      </Grid>
      <Grid item lg={12} className={classes.infoSubtitle}>
        <Typography variant='h3' component='h3' align='left'>
          {t('showVerificationResult.technicalProblem.text2')}
        </Typography>
      </Grid>
      <Grid item lg={12}>
        <Typography
          variant='body1'
          align='left'
          className={classes.infoTextMargin}
        >
          {parse(t('showVerificationResult.technicalProblem.text3'))}
        </Typography>
      </Grid>
    </Grid>
  );
};

export const UserInfo = ({
  requestResult = RequestResult.UNKNOWN_PROBLEM,
}: Props) => {
  switch (requestResult) {
    case RequestResult.SUCCESS:
      return <Success />;
    case RequestResult.UNKNOWN_PROBLEM:
      return <UnknownProblem />;
    case RequestResult.UNEXPECTED_CANCELLATION:
      return <UnexpectedCancellation />;
    case RequestResult.NAME_MISMATCH:
      return <NameMismatch />;
    case RequestResult.DOCUMENT_EXPIRED:
      return <DocumentExpired />;
    case RequestResult.TECHNICAL_PROBLEM:
      return <TechnicalProblem />;
    case RequestResult.MISSING_AUTHORIZED_REPRESENTATIVE:
      return <MissingAuthorizedRepresentative />;
    case RequestResult.ORDER_PRESETS_MISMATCH:
      return <OrderPresetsMismatch />;
    default:
      return <UndefinedState requestResult={requestResult} />;
  }
};
