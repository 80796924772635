import {useEffect, useRef, useState} from 'react';

import { CircularProgress } from '@material-ui/core';
import { t } from 'i18next';

import { waitForVerificationStyles } from '../../CrefoTheme';
import { useAppDispatch, useAppSelector } from '../../state/hooks';
import {
  getIdentState,
  selectIdent,
  sendCredentials,
} from '../../state/ident/identSlice';

export const MyEsgVerification = () => {
  const dispatch = useAppDispatch();
  const classes = waitForVerificationStyles();
  const identState = useAppSelector(selectIdent);

  const timerIdRef = useRef<any>(null);
  const [isPollingEnabled, setIsPollingEnabled] = useState(true);
  const isFirstRun = useRef(true);

  useEffect(() => {
    const pollingCallback = () => {
      dispatch(getIdentState());
    };

    const startPolling = () => {
      timerIdRef.current = setInterval(pollingCallback, 3000);
    };

    const stopPolling = () => {
      clearInterval(timerIdRef.current);
    };

    if (isPollingEnabled) {
      startPolling()
    }

    if (!isPollingEnabled) {
      stopPolling()
      dispatch(sendCredentials())
    }

    return () => {
      stopPolling();
    };
  }, [isPollingEnabled]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }

    const credentialState = identState.meta?.credentialState;

    const userReceivedAllRequiredCredentialTypes =
        credentialState?.personCredentialState?.fulfilled &&
        credentialState?.representationCredentialState?.fulfilled &&
        !credentialState?.companyCredentialState?.missingCredentials.includes('crefoId');

    if (userReceivedAllRequiredCredentialTypes) {
      setIsPollingEnabled(false);
      return;
    }
  }, [
    identState,
  ]);

  return (
      <>
        <CircularProgress size={80} className={classes.myEsgSpinner} />
        <div className={classes.container}>
          <h3 className={classes.headline}>{t('waitForVerification.text2')}</h3>
        </div>
      </>
  );
};
