import { makeStyles } from '@material-ui/core/styles';

import { defaultTheme } from '../../CrefoTheme';

export const CompanySearchStyles = makeStyles({
  contentContainer: {
    [defaultTheme.breakpoints.down('sm')]: {
      marginTop: '0px !important',
      minHeight: 'calc(100vh - 270px)',
    },
  },
  mobileContent: {
    [defaultTheme.breakpoints.down('sm')]: {
      paddingBottom: '2.5rem' /* footer height */,
      minHeight: '50vh',
    },
  },
  identityCard: {
    position: 'relative',
    width: '300px',
    height: '180px',
    marginTop: '30px',
  },
  secondaryHelpPopper: {
    width: '400px',
    marginTop: '10px',
  },
  signatorySubtitle: { marginTop: 10, marginBottom: 5 },
  margin: { marginTop: '30px' },
  searchMargin: { marginTop: '20px' },
  rightPadding: { paddingRight: 0 },
  emptySignatoryItem: { height: '100%', width: 160 },
  signatoryRow: { paddingBottom: 20 },
  signatoryGroup: { marginTop: '20px' },
  signatoryText: {
    [defaultTheme.breakpoints.up('md')]: {
      textAlign: 'left',
    },
    [defaultTheme.breakpoints.down('md')]: {
      textAlign: 'left',
      fontSize: '20px',
      fontWeight: 'bold',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.5px',
    },
    margin: { marginTop: '30px' },
    searchMargin: { marginTop: '20px' },
    rightPadding: { paddingRight: 0 },
  },
});
