import { Grid, Typography, Paper, Button, Link } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { identProviderCardStyles } from './IdentProviderCard.style';
import { IdentRequestDtoSelectedIdentProviderEnum } from "../../api-clients/core-service-api-react";

interface IdentProviderCardProps {
  identProvider: IdentRequestDtoSelectedIdentProviderEnum;
  title: string;
  subtitle: string;
  image: string;
  description: string;
  entries: string[];
  link: string;
  isSelected: boolean;
  setSelectedIdentProvider: (identProvider: IdentRequestDtoSelectedIdentProviderEnum) => void;
  setShowSupportedDocs: (identProvider: IdentRequestDtoSelectedIdentProviderEnum | undefined) => void;
}

export const IdentProviderCard = (props: IdentProviderCardProps) => {
  const { t } = useTranslation();
  const {
    identProvider,
    title,
    subtitle,
    image,
    description,
    entries,
    link,
    isSelected,
    setSelectedIdentProvider,
    setShowSupportedDocs,
  } = props;
  const highlightOffset = 20;
  const classes = identProviderCardStyles({ highlightOffset })();

  return (
    <Grid
      container
      item
      xs={12}
      sm={9}
      md={5}
      lg={5}
      className={classes.container}
    >
      <Paper elevation={3} className={classes.paper}>
        <Grid container className={classes.contentContainer}>
          <Grid container justifyContent='center'>
            <Typography variant='h3' component='h3' align='center'>
              {title}
            </Typography>
          </Grid>
          <Grid container item justifyContent='center'>
            <Typography
              variant='body1'
              align='center'
              style={{ margin: '10px 0px' }}
            >
              {subtitle}
            </Typography>
          </Grid>
          <Grid container justifyContent='center' alignItems='center'>
            <Grid
              container
              spacing={0}
              alignContent='center'
              justifyContent='center'
              direction='column'
              style={{ width: '60%' }}
              className={classes.imageContainer}
            >
              <Grid item>
                <img src={image} alt='user' />
              </Grid>
            </Grid>
            <Grid container item className={classes.buttonContainer}>
              <Button
                disabled={false}
                onClick={() => {
                  setSelectedIdentProvider(identProvider);
                }}
                className={`${classes.outlinedSecondary} rounded ${
                  isSelected ? 'clicked' : ''
                }`}
              >
                {isSelected
                  ? t('identProviderCard.selectedText')
                  : t('identProviderCard.unselectedText')}
              </Button>
            </Grid>
            <Typography style={{ margin: '10px -10px' }}>
              <b>{description}</b>
            </Typography>
          </Grid>
          <Grid container alignItems='center'>
            <Grid item>
              {entries?.map((value, index) => (
                <Typography key={index} align='left'>
                  {t(value)}
                </Typography>
              ))}
            </Grid>
            <Grid item>
              {link && (
                <Typography variant='body1' align='left'>
                  <Link
                    href='#'
                    onClick={() => setShowSupportedDocs(identProvider)}
                  >
                    {link}
                  </Link>
                </Typography>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};
