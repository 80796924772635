import { Button, Hidden } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useDispatch } from "react-redux";

import { NavigationStyles } from './Navigation.styles';
import { triggerPersonCredentialReset } from "../../state/ident/identSlice";

export const RepeatVerificationButton = () => {
  const dispatch = useDispatch();
  const classes = NavigationStyles();
  const { t } = useTranslation();
  const handleRepeatVerification = () => {
    dispatch(triggerPersonCredentialReset())
  }

  return (
    <>
      <Hidden smDown>
        <Button
          data-testid='continueBtn'
          className={classes.nextButton}
          onClick={handleRepeatVerification}
        >
          {`${t('showVerificationResult.repeatVerification')}`}
        </Button>
      </Hidden>
      <Hidden mdUp>
        <Button
          data-testid='continueBtn'
          className={classes.smallButton}
          onClick={handleRepeatVerification}
        >
          {`${t('showVerificationResult.repeatVerification')}`}
        </Button>
      </Hidden>
    </>
  );
};
