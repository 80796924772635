
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
  List,
  ListItem,
  Avatar,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useTranslation } from 'react-i18next';

import { ApproveCredentialsStyles } from './ApproveCredentials.style';

export const VerifiedAccountAccordion = (props) => {
  const classes = ApproveCredentialsStyles();
  const { t } = useTranslation();
  const {
    hostname,
  } = props;

  return (
    <Accordion className={classes.accordion}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        className={classes.accordionSummary}
      >
        <Grid
          container
          direction='row'
          justifyContent='flex-start'
          alignItems='center'
        >
          <Grid item lg={1}>
            <Avatar alt='identity' src='identity.svg' />
          </Grid>
          <Grid item lg={11}>
            <Typography className={classes.accordionSummaryRightColumn}>
              {`${t('approveCredentials.personText')}`}
            </Typography>
          </Grid>
        </Grid>
      </AccordionSummary>

      <AccordionDetails className={classes.accordionDetails}>
        <Grid
          container
          direction='row'
          justifyContent='flex-end'
          alignItems='flex-end'
        >
          <Grid item lg={11}>
            <Typography
              className={classes.accordionSummaryRightColumn}
              style={{ fontWeight: 'bold' }}
            >
              {`${t(
                'approveCredentials.personCredentialAccordionText1'
              )} ${hostname} ${t(
                'approveCredentials.personCredentialAccordionText2'
              )}`}
            </Typography>
          </Grid>
          <Grid item lg={11}>
            <List
              className={classes.accordionSummaryRightColumn}
              variant='body1'
              dense
            >
              <ListItem className={classes.accordionListItem}>
                <span className={classes.highlightedTextRegular}>-&nbsp;</span>

                {`${t('approveCredentials.verifiedAccount')}`}
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
